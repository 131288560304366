<template>
  <div class="work-card g-10 bg-light br-r-20 pd-fr-20">
        <div class="flex flex-row flex-center flex-between mr-b-20">
            <p class="t-5 t-c-pr t-w-sb t-lh-13">{{cardInfo.name}}</p>
            <p class="p-l t-c-sc t-w-m ff-alter">{{cardInfo.jobType}}</p>
        </div>
        <div class="flex flex-row job-steck" v-if="cardInfo.steck">
            <p v-for="tag in cardInfo.steck" :key="tag" class="p-l t-w-m t-c-sc bg-white br-r-100 pd-fr-8 mr-r-10 mr-b-10 job-steck-item">
                {{tag}}
            </p>
        </div>
        <p class="p-2 t-c-sc t-lh-15 ff-alter mr-b-20">{{cardInfo.about}}</p>
        <Btn :path="`/career/${categoryValue}/${cardInfo.id}`" Size="m">Подробнее</Btn>
    </div>
</template>

<script>
export default {
    name: 'JobCard',
    props: {
        cardInfo: {
            type: Object
        },
        categoryValue: {
            type: String
        }
    },
}
</script>

<style lang="scss" scoped>
        .g-10 {
            grid-column-start: 2;
        }
        @media screen and (max-width: $tablet-portrait) {
            .g-10 {
                .job-steck {
                    margin-bottom: $s-10;
                    .job-steck-item {
                        margin-bottom: $s-10;
                    }
                }
                
            }
        }
        @media screen and  (max-width: $phone-portrait) {
            .g-10 {
                .flex-row:first-child {
                    .p-l {margin-top: $s-10}
                }
                .flex-row:nth-child(2) {
                    .p-l {margin-bottom: $s-10;}
                }
            }
        }
</style>