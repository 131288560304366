<template>
    <section id="values">
        <h3 class="t-3 t-w-sb t-c-pr t-center ff-alter mr-b-40">Наши ценности</h3>
            <Tabs @currentTab="activeTab" Center  class="mr-b-40" Size="s" :active="tabActive" :Items="valueTabs"/>
            <div class="wrapper">
                <div class="g-12 pd-fr-40 bg-light br-r-20" v-show="tabActive === 'balance'">
                    <div class="wrapper">
                        <div class="g-5">
                            <div class="img-wrapper br-r-12">
                                <img :src="require('@/assets/img/values/balance.jpg')" alt="balance" class="img">
                            </div>
                        </div>
                        <div class="g-7">
                            <h4 class="t-4 t-w-sb mr-b-10">Баланс</h4>
                            <Ul :Items="balance"/>
                        </div>
                    </div>
                </div>
                <div class="g-12 pd-fr-40 bg-light br-r-20" v-show="tabActive === 'team'">
                    <div class="wrapper">
                        <div class="g-5">
                            <div class="img-wrapper br-r-12">
                                <img :src="require('@/assets/img/values/team.jpg')" alt="balance" class="img">
                            </div>
                        </div>
                        <div class="g-7">
                            <h4 class="t-4 t-w-sb mr-b-10">Команда</h4>
                            <p class="p-2 t-c-sc t-lh-15 mr-b-40">Взаимодействие через искренность, честность, доверие и открытость. </p>
                            <p class="t-5 t-w-sb mr-b-10">Принципы:</p>
                            <Ul :Items="team"/>
                        </div>
                    </div>
                </div>
                <div class="g-12 pd-fr-40 bg-light br-r-20" v-show="tabActive === 'grow'">
                    <div class="wrapper">
                        <div class="g-5">
                            <div class="img-wrapper br-r-12">
                                <img :src="require('@/assets/img/values/grow.jpg')" alt="balance" class="img">
                            </div>
                        </div>
                        <div class="g-7">
                            <h4 class="t-4 t-w-sb mr-b-10">Развитие</h4>
                            <p class="p-2 t-c-sc t-lh-15 mr-b-40">Постоянный поиск путей и возможностей для себя, команды и продуктов (рассматриваем все возможности).</p>
                            <p class="p-2 t-c-sc t-lh-15 mr-b-40">Цикличность в личном развитии и развитии команды (личное развитие развивает команду, что дает переходить на следующий уровень личного развития).</p>
                            <p class="p-2 t-c-sc t-lh-15 mr-b-40">Нет непреодолимых ограничений.</p>
                        </div>
                    </div>
                </div>
                <div class="g-12 pd-fr-40 bg-light br-r-20" v-show="tabActive === 'clients'">
                    <div class="wrapper">
                        <div class="g-5">
                            <div class="img-wrapper br-r-12">
                                <img :src="require('@/assets/img/values/clients.jpg')" alt="balance" class="img">
                            </div>
                        </div>
                        <div class="g-7">
                            <h4 class="t-4 t-w-sb mr-b-10">Клиенты</h4>
                            <p class="p-2 t-c-sc t-lh-15 mr-b-40">Мы относимся к нашим клиентам как к членам нашей команды.<br>
                                Для нас важно, чтобы миссия клиента была созвучна нашей.</p>
                            <p class="t-5 t-w-sb mr-b-10">Продукты и решения </p>
                            <Ul :Items="clients"/>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import Tabs from "@/components/ui/Tabs.vue";
import Ul from "@/components/ui/Ul.vue";

export default {
    name: 'sValues',
    components: {Ul, Tabs},
    data() {
        return {
            team: [
                'Равноценность каждого члена команды, сотрудничество (фокус на win-win и долгосрочность)',
                'Взаимоуважение (принятие и соблюдение личных границ)',
                'Независимость (наличие у каждого зоны принятия самостоятельных решений с принятием ответственности за них)',
                'Эффективная коммуникация (через просьбу, ответственность и экологичность, а также с учетом культурного контекста)',
                'Согласованность целей сотрудника и компании – на разных уровнях и на разных горизонтах планирования',
                'Гибкость в принятии решений',
                'Возможность взаимопомощи',
                'Самодостаточность',
            ],
            balance: [
              'Инноваций и проверенных решений',
              'Затрат и планируемого результата',
              'Офиса и удалёнки',
              'Личного и общего',
              'Труда и отдыха',
              'Развития и рутины',
            ],
            clients: [
              'Явная ценность для клиента',
              'Справедливая цена'
            ],
            valueTabs: [
                {name: 'Команда', value: 'team'},
                {name: 'Клиенты', value: 'clients'},
                {name: 'Развитие', value: 'grow'},
                {name: 'Баланс', value: 'balance' }
            ],
            tabActive: 'team'
        }
    },
    methods: {
        activeTab(data) {
            this.tabActive = data;
        }
    }
}
</script>

<style lang="scss" scoped>
#values {
	padding-top: $s-40;
	padding-bottom: $s-40;
	.wrapper {
      .g-12 {
          position: relative;
          min-height: 52rem;
          .wrapper {
              height: 100%;
              width: 100%;
              .g-5 {
                  position: relative;
                  .img-wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                      position: relative;
                      overflow: hidden;

                      .img {
                          position: absolute;
                          bottom: 0;
                          height: 100%;
                      }
                  }
              }
          }
      }
	}
}

@media screen and (max-width: $tablet-landscape) {
	#values {
		.wrapper {
			grid-auto-flow: dense;
			grid-template-columns: repeat(10, 1fr);
        .g-12 {
            grid-column-end: span 10;
            .wrapper {
                .g-5 {
                    grid-column-end: span 10;
                    .img-wrapper {
                        width: 100%;
                        min-height: 30rem;
                        .img {
                            width: 100%;
                            height: auto;
                            bottom: -40%;
                        }
                    }
                }
                .g-7 {
                    grid-column-end: span 10;
                }
            }
        }
		}
	}
}

@media screen and (max-width: $tablet-portrait) {
	#values {
		.wrapper {
			.g-12 {
          .wrapper {
              .g-5 {
                .img-wrapper {
                    .img {
                        bottom: 0;
                        height: 100%;
                        width: auto;
                    }
                }
              }
          }
      }
		}
	}
}
</style>
