<template>
 <path
    d="M13.47,10.28,16.77,7a2.45,2.45,0,0,0,.73-1.76,2.5,2.5,0,0,0-4.27-1.77L10.11,6.57l-1,1-.28.28a2.53,2.53,0,0,0-.47,2.9,2.46,2.46,0,0,0,.47.64"
    class="cls-1"
  />
  <path
    d="M11.25,8.75A2.49,2.49,0,0,1,12,10.52a2.56,2.56,0,0,1-.41,1.38h0a2.72,2.72,0,0,1-.31.38l-.25.25-1,1L6.77,16.77a2.5,2.5,0,0,1-3.54-3.54l3.4-3.4"
    class="cls-1"
  />
</template>

<script>
export default {
  name: "iLink",
};
</script>

<style lang="scss" scoped>
.cls-1 {
          fill: none;
          stroke: $white;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }
</style>
