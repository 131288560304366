<template>
<section :id="sectionId" class="hero-list bg-white br-r-20 pd-fr-40">
        <p :class="Size" class="t-center t-w-sb mr-b-40"><slot name="Title"/></p>
        <div class="wrapper">
          <div class="g-4 flex-col bg-light-0 br-r-12 pd-fr-20" v-for="item in Items" :key="item">
            <p class="p-2 t-w-sb br-r-6 bg-green t-c-w num t-center mr-b-10">{{item.num}}</p>
            <p class="p-2 t-c-sc t-w-sb t-lh-15">{{item.text}}</p>
          </div>
        </div>
  </section>
</template>

<script>
export default {
    name: 'sHeroList',
    props: {
        Items: {
            type: Array
        },
        sectionId: {
            type: String,
            default: 'hero-list'
        },
        Size: {
            type: String,
            default: 't-3'
        }
    }
}
</script>

<style lang="scss" scoped>
.hero-list {
    margin-top: $s-40;
    margin-bottom: $s-40;
   .wrapper {
     .g-4 {
       &:last-child{grid-column-start: 5;}
       .num {padding: $s-6; width: $s-30; height: $s-30;}
     }
   }
 }

 @media screen and (max-width: $tablet-landscape) {
     .hero-list {
         .wrapper {
             grid-template-columns: repeat(9, 1fr);
             .g-4 {
                 grid-column-end: span 3;
                 &:last-child {
                     grid-column-start: 4;
                 }
             }
         }
     }
 }

  @media screen and (max-width: $tablet-portrait) {
     .hero-list {
         .wrapper {
             grid-template-columns: repeat(6, 1fr);
             .g-4 {
                 grid-column-end: span 3;
                 &:last-child {
                     grid-column-start: 1;
                 }
             }
         }
     }
 }

 @media screen and (max-width: $phone-landscape) {
     .hero-list {
         .wrapper {
             grid-template-columns: repeat(6, 1fr);
             .g-4 {
                 grid-column-end: span 6;
                 &:last-child {
                     grid-column-start: 1;
                 }
             }
         }
     }
 }

 
</style>