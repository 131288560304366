<template>
  <sHero 
  :subSize="'p-1'"
  :marginBottom="'pd-b-40'"
  >
    <template #Title>
      ТП Кит
    </template>
    <template #Description>
      компания - интегратор наших решений
    </template>
    <template #Subtitle>
      Команда ТП Кит первыми принимает вызов масштабирования наших решений и обеспечивают прочность систем вне зависимости от нагрузки. За плечами успех внедрений и адаптации решений: от одного объекта до сотен объектов по всей России и в ближнем зарубежье. 
    </template>
    <template #Button>
      <div class="flex flex-row flex-just-center">
        <Btn class="mr-b-40 mr-r-20" path="/contacts">Связаться</Btn>
        <Btn  path="/req">Реквизиты</Btn>
      </div>
    </template>
    <div class="link link-1 br-r-20 pd-fr-20 bg-light"><img src="~@/assets/icons/links/tpkit.svg"></div>
    <div class="link link-2 br-r-20 pd-fr-20 bg-light"> <img src="~@/assets/icons/links/tplab.svg"> </div>
  </sHero>
  <sHeroList Size="t-4" :Items="items">
    <template #Title>Возможности команды обеспечены собственным <br> опытом "огонь, вода и медные трубы", а также <br> налаженной работой с проверенными в деле подрядчиками </template>
  </sHeroList>
</template>

<script>
export default { 
  data() {
    return {
      items: [
        {text: 'Консультирование Заказчика по выбору оптимальной комплектации решений', num: '1'},
        {text: 'Сопровождение решений - 1-я и 2-я линия технической поддержки', num: '2'},
        {text: 'Адаптация и разработка технической документации на языке пользователей решений', num: '3'},
        {text: 'Монтаж и установка оборудования', num: '4'},
        {text: 'Обучение пользователей решений', num: '5'},
        {text: 'Управление проектами внедрений на объектах Заказчика', num: '6'},
        {text: 'Комплектация, настройка оборудования под нужды Заказчика и отправка комплекта решений в любую точку на карте', num: '7'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .link {
      display: flex;
      @include flex-center;
      position: absolute;
      z-index: -1;
      &-1 {top: 15%; left: 10%; height: $s-80; width: $s-80;}
      &-2 {bottom: 55%; right: 5%; height: $s-100; width: $s-100;}
    }
  .wrapper {
    .g-4:last-child {
      grid-column-start: 5;
    }
    .bg-green {
      width: fit-content; 
      min-width: $s-30; 
      min-height: $s-30;
      @include flex-center;
    }
  }
  @media screen and (max-width: $tablet-landscape) {
    .link {
      &-1 {top: 5%;}
      &-2 {}
    }
    .wrapper {
      grid-template-columns: repeat (10, 1fr);
      .g-4 {
        grid-column-end: span 5;
        min-height: 16rem;
        &:last-child {grid-column-start: 1;}
      }
    }
  }
  @media screen and (max-width: $tablet-landscape-min) {
    .link {
       transform: scale(0.9);
      &-1 {left: 0%;}
      &-2 {bottom: 10%; right: 0%;}
    }
    .wrapper {
      .g-4 {grid-column-end: span 4;}
    }
  }
  @media screen and (max-width: $tablet-portrait) {
    br {display: none;}
    .wrapper {
      .g-4 {grid-column-end: span 3;}
    }
  }
  @media screen and (max-width: $phone-landscape) {
    .wrapper {
      .g-4 {grid-column-end: span 4;}
    }
  }
  @media screen and (max-width: $phone-portrait) {
    .link {
      &-1 {top: 0;}
      &-2 {bottom: 0; right: -5%;}
    }
  }


</style>