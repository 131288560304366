<template>
  <section :id="id" class="attention">
    <div class="wrapper">
      <div class="g-10">
        <p class="t-2 t-w-b t-c-w t-center mr-b-20">
            <slot name="Title">Заголовок</slot>
        </p>
        <p class="t-4 t-lh-15 t-c-w ff-alter t-center mr-b-20">
          <slot name="Paragraph">Параграф</slot>
        </p>
        <slot name="Button"/>
        <slot/>
        <div class="mask"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'sAtention',
    props: {
        id: {
            type: String,
            defualt: 'atention'
        }
    }
}
</script>

<style lang="scss" scoped>
.attention {
  padding-top: $s-80;
  padding-bottom: $s-120;
  position: relative;
  .wrapper {
    .g-10 {
      grid-column-start: 2;
      position: relative;
      padding: $s-120 0 $s-120;
      .mask {
        display: block;
        position: absolute;
        height: 90%;
        width: 110%;
        top: 5%;
        left: -5%;
        z-index: -1;
        background: url("~@/assets/masks/mask-bg.png") no-repeat center / 100% 100%;
      }
    }
  }
}
@media screen and (max-width: $tablet-landscape) {
  .attention {
    .wrapper {
      grid-template-columns: repeat(9, 1fr);
      .g-10 {
        grid-column-start: 1;
        grid-column-end: span 9;
        .mask {
            height: 100%;
            top: 0;
            width: 100%;
            left: 0;
          
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-min) {
  .attention {
  }
}

@media screen and (max-width: $tablet-portrait) {
  .attention {
      padding-top: $s-80;
      padding-bottom: $s-80;
    .wrapper {
      .g-10 {
        .mask {
          background: url("~@/assets/masks/mask-bg-2.png") no-repeat center / 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $phone-landscape) {
  .attention {
    .wrapper {
      .g-10 {
        .mask {
          background: url("~@/assets/masks/mask-bg-3.png") no-repeat center / 100% 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $phone-portrait) {
  .attention {
  }
}
</style>
