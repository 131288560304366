<template>
  <section id="technology">
      <p class="t-3 t-c-pr ff-alter t-center t-w-sb mr-b-40">Наши технологии</p>
      <div class="wrapper">
          <div 
          v-for="items in tech"
          :key="items.name"
          :class="items.class"
          class="bg-white br-r-20 pd-fr-20"
          >
              <p class="t-5 t-w-sb mr-b-20">{{items.name}}</p>
              <div class="flex flex-row">
                <p
                v-for="item in items.items"
                :key="item" 
                class="bg-light-0 br-r-20 pd-fr-8 p-l t-w-m t-c-sc mr-r-10 mr-b-10"
                >{{item}}</p>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    name: 'sTechnologies',
    data() {
        return {
            tech: [
                {name: 'AI/CV', class:'g-2', items: ['OpenCV', 'PyTorch']}, 
                {name: 'Product', class:'g-3', items: ['JTBD','AAARRRR','Growth Hacking']},
                {name: 'Teamwork', class:'g-2', items: ['Telegram', 'Notion','YouTrack']},
                {name: 'UI/UX', class:'g-3', items: ['ReactJS','QML', 'Vue', 'Native Android']}, 
                {name: 'Backend', class:'g-2', items: ['Django', 'RabbitMQ', 'Flask']},  
                {name: 'DBMS', class:'g-2', items: ['PostgreSQL','MongoDB', 'redis', 'ClickHouse']},  
                {name: 'Happiness', class:'g-3', items: ['Office Yoga','КВИЗ, Плиз!', 'Пятницы', 'Корпоративы', 'Пупырка']},
                {name: 'CI/CD', class:'g-4', items: ['TeamCity','Ansible', 'Docker']},
                {name: 'Management', class:'g-3', items: ['360', '1-to-1', 'OKR']},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    #technology {
        padding-top: $s-40;
        padding-bottom: $s-40;
        .g-1,.g-2,.g-3,.g-4,.g-5,.g-6 {
            min-height: 20rem;
        }
        .flex-inline {
            width: fit-content;
        }
    }
    @media screen and (max-width: $tablet-landscape) {
        #technology {
            .wrapper {
                grid-template-columns: repeat(10, 1fr);
                .g-4 {grid-column-end: span 2;}
                .g-3 {
                    &:nth-child(2){grid-column-end: span 4;}
                    &:nth-child(4){grid-column-end: span 4;}
                    &:nth-child(6){grid-column-end: span 3;}
                    &:nth-child(7){grid-column-end: span 3;}
                    &:nth-child(9){grid-column-end: span 5;}
                    grid-column-end: span 3;
                }
                .g-2 {
                    &:nth-child(1){grid-column-end: span 3;}
                    &:nth-child(3){grid-column-end: span 3;}
                    &:nth-child(5){grid-column-end: span 3;}
                    &:nth-child(6){grid-column-end: span 3;}
                    grid-column-end: span 2;
                }
            }
        }
    }
    @media screen and (max-width: $tablet-portrait) {
        #technology {
            .wrapper {
                .g-1,.g-2,.g-3,.g-4,.g-5,.g-6 {
                    min-height: fit-content;
                }
                grid-template-columns: repeat(9, 1fr);
                .g-5 {grid-column-end: span 5;}
                .g-4 {grid-column-end: span 5;}
                .g-2 {
                    &:nth-child(1) {grid-column-end: span 4;}
                    &:nth-child(3) {grid-column-end: span 5;}
                    &:nth-child(5) {grid-column-end: span 4;}
                    &:nth-child(6) {grid-column-end: span 5;}
                }
                .g-3{
                    &:nth-child(2) {grid-column-end: span 5;}
                    &:nth-child(6) {grid-column-end: span 4;}
                    &:nth-child(7) {grid-column-end: span 9;}
                    &:nth-child(9) {grid-column-end: span 4;}
                }
            }
        }
    }
    @media screen and (max-width: $phone-portrait) {
        #technology {
            .wrapper {
                grid-template-columns: repeat(4, 1fr);
                .g-2, .g-3, .g-5 {
                    grid-column-end: span 2;
                }
                .g-4 {grid-column-end: span 4;}
                .g-2 {
                    &:nth-child(1) {grid-column-end: span 2;}
                    &:nth-child(3) {grid-column-end: span 2;}
                    &:nth-child(5) {grid-column-end: span 2;}
                    &:nth-child(6) {grid-column-end: span 2;}
                }
                .g-3 {
                    &:nth-child(2) {grid-column-end: span 2;}
                    &:nth-child(4) {grid-column-end: span 2;}
                    &:nth-child(7) {grid-column-end: span 4;}
                    &:nth-child(9) {grid-column-end: span 4;}
                }
            }
        }
    }
</style>