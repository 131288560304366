<template>
  <section id="privacy" class="mr-b-50 mr-t-20">
    <h3 class="t-3 t-c-g ff-alter t-w-b mr-b-20">ООО «ТП ЛАБ»</h3>
    <h1 class="t-c-pr t-5 t-w-sb mr-b-50">Политика конфиденциальности персональных данных посетителей сайта<br> в информационно-телекоммуникационной сети "Интернет"</h1>
    <h5 class="t-5 t-w-m mr-b-20 t-c-sc">1. Общие положения</h5>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">1.1. Настоящая политика конфиденциальности разработана в соответствии с положениями Конституции Российской Федерации, Федерального закона от 27 июля 2006 г. N 149-ФЗ "Об информации, информационных технологиях и о защите информации", Федерального закона от 27 июля 2006 г. N 152-ФЗ "О персональных данных" (далее - Закон о персональных данных) и иными нормативными правовыми актами в области защиты и обработки персональных данных, действующими на территории Российской Федерации.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">1.2. В настоящей политике конфиденциальности используются следующие понятия:</p>
    <ul class="t-c-pr t-lh-15 p-2 mr-b-20">
        <li class="mr-b-20 pd-l-10 t-w-m">- сайт – truepositive.ru, расположенный на доменном имени truepositive.ru, предоставляющий услуги по продаже и технической поддержке ПАК RetailRotor, ПАК KnotInspetor, ПО FMMonitor, ПО LogHouse, ПО ПоЗаписи;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- администрация сайта - уполномоченные на управление сайтом работники, определяющие состав персональных данных пользователей сайта, цели сбора персональных данных, их обработку и хранение;<br></li>
        <li class="mr-b-20 pd-l-10 t-w-m">- пользователь сайта - физическое лицо, пользователь услуг сайта, субъект персональных данных, добровольно зарегистрировавшийся на сайте и предоставивший необходимые персональные данные при регистрации;<br></li>
        <li class="mr-b-20 pd-l-10 t-w-m">- персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);<br></li>
        <li class="mr-b-20 pd-l-10 t-w-m">- обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</li>
    </ul>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        1.3. Настоящая политика конфиденциальности устанавливает порядок получения, защиты, хранения, обработки и передачи персональных данных пользователей сайта, действует в отношении всей информации, которую администрация сайта может получить о пользователях во время использования ими сайта.<br>
        Настоящая политика конфиденциальности не распространяется на другие сайты и не применяется в отношении сайтов третьих лиц. Администрация сайта не несет ответственность за сайты третьих лиц, на которые пользователи могут перейти по ссылкам, доступным на сайте.<br>
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        1.4. К персональным данным пользователей сайта относятся: ФИО, номер телефона, адрес электронной почты.<br>
        К данным, которые передаются в автоматическом режиме в зависимости от настроек программного обеспечения, относятся данные о: фамилии, имени, отчестве, контактном телефоне, адресе электронной почты.<br>
        Все персональные данные о пользователях администрация сайта может получить только от них самих.<br>
        Персональные данные пользователей сайта являются конфиденциальной информацией и не могут быть использованы администрацией сайта или любым иным лицом в личных целях.<br>
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">1.5. Цели обработки персональных данных пользователей сайта:<br></p>
    <ul class="t-c-pr t-lh-15 p-2 mr-b-20">
        <li class="mr-b-20 pd-l-10 t-w-m">1. Получение по запросу информации о ценах, конфигурациях, возможных комплектациях, условиях поставки продуктов и оказания услуг ООО "ТП Лаб".<br></li>
        <li class="mr-b-20 pd-l-10 t-w-m">2. Получение технической поддержки по использованию ПАК RetailRotor, ПАК KnotInspetor, ПО FMMonitor, ПО LogHouse, ПО ПоЗаписи.</li>
    </ul>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">1.6. Администрация сайта обеспечивает пользователям свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей их персональные данные, за исключением случаев, предусмотренных законодательством.<br></p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">1.7. Администрация сайта разрабатывает меры защиты персональных данных пользователей сайта.<br></p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20"></p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20"></p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">
        2. Обработка, хранение и передача персональных данных пользователей сайта
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.1. Обработка персональных данных пользователей сайта осуществляется исключительно в целях, указанных в п. 1.5. настоящей политики конфиденциальности.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.2. Обработка персональных данных на сайте осуществляется как с использованием средств автоматизации, так и без использования таких средств.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.3. К категориям субъектов персональных данных относятся:</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        2.3.1. Пользователи сайта.
        В данной категории субъектов администрацией сайта обрабатываются персональные данные:<br>
        - в целях консультирования субъектов о возможности приобретения и обслуживания продуктов компании:</p>
    <ul class="t-c-pr t-lh-15 p-2 mr-b-30 wrapper">
        <li class="g-4 frame frame-light pd-fr-20 br-r-20">
            <span class="mr-b-20 t-w-m t-c-sc">Категория персональных данных:</span>
            <span class="t-c-pr t-w-m">только общие персональные данные</span>
        </li> 
        <li class="g-4 frame frame-light pd-fr-20 br-r-20">
            <span class="mr-b-20 t-w-m t-c-sc">Перечень персональных данных:</span>
            <span class="t-c-pr t-w-m">
                фамилия, имя, отчество,
                контактный телефон, адрес электронной почты	
            </span>
        </li>
        <li class="g-4 frame frame-light pd-fr-20 br-r-20">
            <span class="mr-b-20 t-w-m t-c-sc">Способ обработки:</span>
            <span class="t-c-pr t-w-m">с использованием автоматизированных систем</span>
        </li>
        <li class="g-4 frame frame-light pd-fr-20 br-r-20">
            <span class="mr-b-20 t-w-m t-c-sc">Срок обработки:</span>
            <span class="t-c-pr t-w-m">5 дней</span>
        </li>
        <li class="g-4 frame frame-light pd-fr-20 br-r-20">
            <span class="mr-b-20 t-w-m t-c-sc">Срок хранения:</span>
            <span class="t-c-pr t-w-m">5 лет</span>
        </li>
        
    </ul>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        2.4. Персональные данные пользователей сайта хранятся в электронном виде в информационной системе персональных данных сайта, а также в архивных копиях баз данных сайта.
        При хранении персональных данных пользователей сайта соблюдаются организационные и технические меры, обеспечивающие их сохранность и исключающие несанкционированный доступ к ним.
        К обработке персональных данных пользователей сайта могут иметь доступ только работники администрации сайта, допущенные к работе с персональными данными пользователей сайта и подписавшие соглашение о неразглашении персональных данных пользователей сайта.
        Перечень работников сайта, имеющих доступ к персональным данным пользователей сайта, утверждается приказом №2/22-ОД от 01.09.2022г.
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">

    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        2.5. Администрация сайта может передавать персональные данные пользователей сайта третьим лицам, только если это необходимо в целях предупреждения угрозы их жизни и здоровью, а также в случаях, установленных законодательством.
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        2.6. Администрация сайта обязана предоставлять персональные данные пользователей только уполномоченным лицам и только в той части, которая необходима им для выполнения их трудовых обязанностей, в соответствии с настоящей политикой конфиденциальности и законодательством Российской Федерации.
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.7. При передаче персональных данных пользователей сайта администрация сайта предупреждает лиц, получающих данную информацию, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требует от этих лиц письменное подтверждение соблюдения этого условия.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.8. Согласие на обработку персональных данных, разрешенных пользователем сайта для распространения, оформляется отдельно от иных согласий пользователя сайта на обработку его персональных данных. Администрация сайта обеспечивает пользователю сайта возможность определить перечень персональных данных по каждой категории персональных данных, указанной в согласии на обработку персональных данных, разрешенных им для распространения.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.9. В согласии на обработку персональных данных, разрешенных пользователем сайта для распространения, он вправе установить запреты на передачу (кроме предоставления доступа) этих персональных данных администрацией сайта неограниченному кругу лиц, а также запреты на обработку или условия обработки (кроме получения доступа) этих персональных данных неограниченным кругом лиц.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.10. Передача (распространение, предоставление, доступ) персональных данных, разрешенных пользователем сайта для распространения, должна быть прекращена в любое время по его требованию. Указанные в данном требовании персональные данные могут обрабатываться только администрацией сайта.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.11. Иные права, обязанности, действия работников администрации сайта, в трудовые обязанности которых входит обработка персональных данных пользователей сайта, определяются должностными инструкциями.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.12. Все сведения о передаче персональных данных пользователей сайта учитываются для контроля правомерности использования данной информации лицами, ее получившими.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">2.13. В целях повышения качества сервиса и обеспечения возможности правовой защиты администрация сайта вправе хранить лог-файлы о действиях, совершенных пользователями в рамках использования сайта.</p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">3. Требования к помещениям, в которых производится обработка персональных данных</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">3.1. Размещение оборудования информационных систем персональных данных, специального оборудования и охрана помещений, в которых ведется работа с персональными данными, организация режима обеспечения безопасности в этих помещениях должны обеспечивать сохранность носителей персональных данных и средств защиты информации, а также исключать возможность неконтролируемого проникновения или пребывания в этих помещениях посторонних лиц.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">3.2. Помещения, в которых располагаются технические средства информационных систем персональных данных или хранятся носители персональных данных, должны соответствовать требованиям пожарной безопасности, установленным действующим законодательством Российской Федерации.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">3.3. Определение уровня специального оборудования помещения осуществляется специально создаваемой комиссией. По результатам определения класса и обследования помещения на предмет его соответствия такому классу составляются акты.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">3.4. Кроме указанных мер по специальному оборудованию и охране помещений, в которых устанавливаются криптографические средства защиты информации или осуществляется их хранение, реализуются дополнительные требования, определяемые методическими документами Федеральной службы безопасности России.</p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">4. Права и обязанности администрации сайта</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.1. Администрация сайта вправе устанавливать требования к составу персональных данных пользователей, которые должны обязательно предоставляться для использования сайта, при этом администрация сайта руководствуется настоящей политикой конфиденциальности, Конституцией Российской Федерации, иными федеральными законами.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.2. Администрация сайта не осуществляет проверку достоверности предоставляемых пользователями сайта персональных данных, полагая, что они действуют добросовестно и поддерживают информацию о своих персональных данных в актуальном состоянии.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.3. Администрация сайта не несет ответственности за добровольную передачу пользователями сайта своих контактных данных, пароля или логина третьим лицам.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.4. Администрация сайта не вправе получать и обрабатывать персональные данные пользователей сайта о их политических, религиозных и иных убеждениях и частной жизни.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.5. Администрация сайта за свой счет обеспечивает защиту персональных данных пользователей сайта от неправомерного использования или утраты в порядке, установленном законодательством Российской Федерации.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">4.6. Администрация сайта принимает меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами. Администрация сайта самостоятельно определяет состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами. К таким мерам, в частности, относятся:</p>
    <ul class="t-c-pr t-lh-15 p-2 mr-b-20">
        <li class="mr-b-20 pd-l-10 t-w-m">- назначение ответственного за организацию обработки персональных данных;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- издание документов, определяющих политику сайта в отношении обработки персональных данных, локальных актов по вопросам обработки персональных данных, определяющих для каждой цели обработки персональных данных категории и перечень обрабатываемых персональных данных, категории субъектов, персональные данные которых обрабатываются, способы, сроки их обработки и хранения, порядок уничтожения персональных данных при достижении целей их обработки или при наступлении иных законных оснований, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства Российской Федерации, устранение последствий таких нарушений. Такие документы и локальные акты не могут содержать положения, ограничивающие права пользователей сайта, а также возлагающие на администрацию сайта не предусмотренные законодательством Российской Федерации полномочия и обязанности;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- применение правовых, организационных и технических мер по обеспечению безопасности персональных данных;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных Закону о персональных данных и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, политике сайта в отношении обработки персональных данных, локальным актам сайта;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- оценка вреда, который может быть причинен пользователям сайта в случае нарушения Закона о персональных данных, соотношение указанного вреда и принимаемых администрацией сайта мер, направленных на обеспечение выполнения обязанностей, предусмотренных Законом о персональных данных;</li>
        <li class="mr-b-20 pd-l-10 t-w-m">- ознакомление работников сайта, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о персональных данных, в том числе требованиями к защите персональных данных, документами, определяющими политику сайта в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных, и (или) обучение указанных работников.</li>   
    </ul>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">5. Права пользователей сайта на защиту своих персональных данных</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">5.1. Пользователи сайта в целях обеспечения защиты своих персональных данных, хранящихся на сайте, имеют право:</p>
    <ul class="t-c-pr t-lh-15 p-2 mr-b-20">
        <li class="pd-l-10 mr-b-20 t-w-m">- получать полную информацию о своих персональных данных, их обработке, хранении и передаче;</li>
        <li class="pd-l-10 mr-b-20 t-w-m">- определять своих представителей для защиты своих персональных данных;</li>
        <li class="pd-l-10 mr-b-20 t-w-m">- требовать исключения или исправления неверных или неполных персональных данных, а также данных, обработанных с нарушениями настоящей политики конфиденциальности и законодательства Российской Федерации;</li>
        <li class="pd-l-10 mr-b-20 t-w-m">- требовать от администрации сайта извещения всех лиц, которым ранее были сообщены неверные или неполные персональные данные пользователей сайта, обо всех произведенных в них исключениях, исправлениях или дополнениях.</li>
        При отказе администрации сайта исключить или исправить персональные данные пользователей сайта пользователи вправе заявить администрации сайта в письменном виде о своем несогласии с соответствующим обоснованием.
    </ul>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">5.2. Пользователи сайта вправе самостоятельно ограничить сбор информации третьими лицами, используя стандартные настройки конфиденциальности применяемого ими для работы с сайтом интернет-браузера, а также в любое время изменить, удалить или дополнить представленные ими персональные данные.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">5.3. Если пользователи сайта считают, что обработка их персональных данных осуществляется с нарушением требований Закона о персональных данных или иным образом нарушает их права и свободы, они вправе обжаловать действия или бездействие администрации сайта в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">5.4. Пользователи сайта вправе в любой момент самостоятельно отредактировать в своем личном кабинете предоставленные ими при регистрации или авторизации персональные данные.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">5.5. Пользователи сайта не должны отказываться от своих прав на сохранение и защиту тайны.</p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">6. Порядок уничтожения, блокирования персональных данных</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.1. В случае выявления неправомерной обработки персональных данных при обращении пользователя сайта администрация сайта осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому пользователю, с момента такого обращения на период проверки.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.2. В случае выявления неточных персональных данных при обращении пользователя сайта администрация сайта осуществляет блокирование персональных данных, относящихся к этому пользователю, с момента такого обращения на период проверки, если блокирование персональных данных не нарушает права и законные интересы пользователя сайта или третьих лиц.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.3. В случае подтверждения факта неточности персональных данных администрация сайта на основании сведений, представленных пользователем сайта, или иных необходимых документов уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает блокирование персональных данных.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.4. В случае выявления неправомерной обработки персональных данных, осуществляемой администрацией сайта, администрация сайта в срок, не превышающий трех рабочих дней с даты этого выявления, прекращает неправомерную обработку персональных данных.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.5. В случае если обеспечить правомерность обработки персональных данных невозможно, администрация сайта в срок, не превышающий десяти рабочих дней с даты выявления неправомерной обработки персональных данных, уничтожает такие персональные данные.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.6. Об устранении допущенных нарушений или об уничтожении персональных данных администрация сайта уведомляет пользователя сайта.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        6.7. В случае установления факта неправомерной или случайной передачи (предоставления, распространения, доступа) персональных данных, повлекшей нарушение прав пользователя сайта, администрация сайта с момента выявления такого инцидента администрацией сайта, уполномоченным органом по защите прав субъектов персональных данных или иным заинтересованным лицом уведомляет уполномоченный орган по защите прав субъектов персональных данных:
        <ul class="t-lh-15 p-2 mr-b-20">
        <li class="mr-b-20 pd-l-10 t-w-m"> -в течение двадцати четырех часов о произошедшем инциденте, о предполагаемых причинах, повлекших нарушение прав пользователя сайта, и предполагаемом вреде, нанесенном правам пользователя сайта, о принятых мерах по устранению последствий соответствующего инцидента, а также предоставляет сведения о лице, уполномоченном администрацией сайта на взаимодействие с уполномоченным органом по защите прав субъектов персональных данных, по вопросам, связанным с выявленным инцидентом;</li>
        <li class="mr-b-20 pd-l-10 t-w-m"> -в течение семидесяти двух часов о результатах внутреннего расследования выявленного инцидента, а также предоставляет сведения о лицах, действия которых стали причиной выявленного инцидента (при наличии).</li>
        </ul>
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.8. В случае достижения цели обработки персональных данных администрация сайта прекращает обработку персональных данных и уничтожает персональные данные в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.9. В случае отзыва пользователем сайта согласия на обработку его персональных данных администрация сайта прекращает их обработку и в случае, если сохранение персональных данных более не требуется для целей обработки персональных данных, уничтожает персональные данные в срок, не превышающий тридцати дней с даты поступления указанного отзыва.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        6.10. В случае обращения пользователя сайта к администрации сайта с требованием о прекращении обработки персональных данных администрация сайта в срок, не превышающий десяти рабочих дней с даты получения ей соответствующего требования, прекращает их обработку, за исключением случаев, предусмотренных Законом о персональных данных.
        Указанный срок может быть продлен, но не более чем на пять рабочих дней в случае направления администрацией сайта в адрес пользователя сайта мотивированного уведомления с указанием причин продления срока предоставления запрашиваемой информации.
    </p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.11. В случае отсутствия возможности уничтожения персональных данных в течение срока, указанного в пунктах 6.4-6.10 настоящей политики конфиденциальности, администрация сайта осуществляет блокирование таких персональных данных и обеспечивает уничтожение персональных данных в срок не более чем шесть месяцев, если иной срок не установлен федеральными законами.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.12. После истечения срока нормативного хранения документов, содержащих персональные данные пользователя сайта, или при наступлении иных законных оснований документы подлежат уничтожению.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">6.13. Администрация сайта для этих целей создает экспертную комиссию и проводит экспертизу ценности документов.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">
        6.14. По результатам экспертизы документы, содержащие персональные данные пользователя сайта и подлежащие уничтожению:
        - на бумажном носителе - уничтожаются путем измельчения в шредере;
        - в электронном виде - стираются с информационных носителей либо физически уничтожаются сами носители, на которых хранится информация.
    </p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">7. Ответственность за нарушение норм, регулирующих обработку и защиту персональных данных пользователей сайта</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">7.1. Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту персональных данных пользователей сайта, привлекаются к дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности в порядке, установленном действующим законодательством Российской Федерации.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">7.2. Моральный вред, причиненный пользователю сайта вследствие нарушения его прав, нарушения правил обработки персональных данных, установленных Законом о персональных данных, а также требований к защите персональных данных, установленных в соответствии с названным Федеральным законом, подлежит возмещению в соответствии с законодательством Российской Федерации. Возмещение морального вреда осуществляется независимо от возмещения имущественного вреда и понесенных пользователем сайта убытков.</p>
    <p class="t-5 t-w-m mr-b-20 t-c-sc mr-t-60">8. Изменение политики конфиденциальности</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">8.1. Настоящая политика конфиденциальности может быть изменена или прекращена администрацией сайта в одностороннем порядке без предварительного уведомления пользователя сайта. Новая редакция политики конфиденциальности вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией политики конфиденциальности.</p>
    <p class="t-c-pr t-lh-15 p-2 mr-b-20">8.2. Действующая редакция политики конфиденциальности находится на сайте в информационно-телекоммуникационной сети "Интернет" по адресу: truepositive.ru/privacy-policy</p>
  </section>
</template>

<script>
export default {
    name: 'privacy-policy',

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: $tablet-landscape) {
    .t-3 { font-size: $s-30;}
    .t-5 {font-size: $s-15;}
}
</style>
