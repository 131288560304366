<template>
    <mainLayout/>
</template>

<script>
import mainLayout from '../src/layouts/mainLayout.vue'
export default {
  components: {
    mainLayout,
  }
}
</script>

<style lang="scss" scoped>

</style>
