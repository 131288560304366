<template>
  <div class="wrapper frame frame-white mr-t-40 mr-b-40 pd-fr-40 br-r-20">
    <div class="g-6">
      <h1 :class="Size" class="t-w-b t-lh-13 mr-b-20 if-empty">
        <slot name="Title">Заголовок</slot>
      </h1>
      <slot name="afterTitile"></slot>
      <p class="p-2 t-w-r t-c-sc t-lh-15 ff-alter mr-b-auto if-empty">
        <slot name="Paragraph">Параграф</slot>
      </p>
      <slot name="afterParagraph" class="if-empty mr-t-20"></slot>
    </div>
    <div class="g-6" v-if="Image === true">
      <div class="image-wrapper if-empty"></div>
        <img class="hero-image" :src="require(`@/assets/${heroImage}`)">
    </div>
  </div>
</template>

<script>
export default {
  name: 'sHeroFlex',
  props: {
    heroImage: {
      type: String,
      default: 'img/ei-crop.png'
    },
    Image: {
      type: Boolean,
      default: true
    },
    Size: {
      type: String,
      default: 't-2'
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    overflow: visible;
    .g-6:nth-child(2) {
      @include flex-center;
      .hero-image {
        width: 100%;
        margin: -$s-40;
        image-orientation: none;
      }
    }
  }
  @media screen and (max-width: $tablet-landscape) {
    .wrapper {
      grid-template-columns: repeat(10, 1fr);
      .g-6 {
        grid-column-end: span 5;
        &:nth-child(2) {
          .hero-image {
            width: 110%;
            margin-top: -10%;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-landscape-min) {
    .wrapper {
      grid-template-columns: repeat(6, 1fr);
      .g-6 {
        grid-column-end: span 6;
        &:first-child {
          order: 1;
        }
      }
    }
  }
  @media screen and (max-width: $phone-portrait) {
    .wrapper {
      grid-column-gap: 4rem;
      grid-row-gap: 4rem;
      .g-6 {
        .t-lh-13 { margin-top: $s-20;}
      }
    }
  }
</style>