import Btn from "@/components/ui/Btn.vue";
import Input from "@/components/ui/Input.vue";
import Link from "@/components/ui/Link.vue";
import Tabs from "@/components/ui/Tabs.vue";
import navBtn from '@/components/ui/navBtn.vue'
import JobCard from '@/components/ui/JobCard.vue'
import Ul from '@/components/ui/Ul.vue'
import ProductCard from '@/components/ui/ProductCard.vue'
import Gallery from '@/components/ui/Gallery.vue'
import ImageGallery from '@/components/ui/ImageGallery.vue'

export default [
    Btn,
    Input,
    Link,
    Tabs,
    navBtn,
    JobCard,
    Ul,
    ProductCard,
    Gallery,
    ImageGallery
]