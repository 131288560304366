<template>
  <div :class="`glide-${galleryName}`" class="glide">
    <div class="glide__track mask" data-glide-el="track" :class="mask">
      <ul class="glide__slides">
        <li class="glide__slide" v-for="item in images" :key="item">
          <div class="img-wrapper">
            <img :src="require(`@/assets/${item.image}`)" :alt="item.alt" />
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="Navigation === true"
      class="glide__arrows"
      data-glide-el="controls"
    >
      <button :class="`glide__arrow-${Size}`" class="glide__arrow glide__arrow--left" data-glide-dir="<">
        <iconFrame><iArrowLeftM /></iconFrame>
      </button>
      <button :class="`glide__arrow-${Size}`" class="glide__arrow glide__arrow--right" data-glide-dir=">">
        <iconFrame><iArrowRightM /></iconFrame>
      </button>
    </div>
    <div
      v-if="Pagination === true"
      class="glide__bullets"
      data-glide-el="controls[nav]"
    >
      <button v-for="nav, index in images" :key="index" class="glide__bullet" :data-glide-dir="`=${index}`"></button>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
export default {
  name: "Gallery",
  props: {
    mask: {
      type: String,
      default: 'mask-1'
    },
    galleryName: {
      type: String,
      default: '1'
    },
    images: {
      type: Array,
    },
    itemsToShow: {
      type: Number,
      default: 1,
    },
    gaps: {
      type: Number,
      default: 0,
    },
    Navigation: {
      type: Boolean,
      default: true,
    },
    Pagination: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: [Number, Boolean],
      default: false,
    },
    Type: {
      type: String,
      default: "carousel",
    },
    Size: {
      type: String,
      default: 'm'
    }
  },
  mounted() {
    new Glide(`.glide-${this.galleryName}`, {
      type: this.Type,
      perView: this.itemsToShow,
      gap: this.gaps,
      autoplay: this.autoPlay,
    }).mount();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/glidejs.scss";
.glide {
  width: 100%;
  height: fit-content;
  position: relative;
  .mask {
      &-1 {mask: url('~@/assets/masks/gallery-mask-1.svg') 0 0 / 100% no-repeat;}
      &-2 {mask: url('~@/assets/masks/gallery-mask-2.svg') 0 0 / 100% no-repeat;} 
  }
  .glide__track {
    .glide__slides {
      .glide__slide {
        max-height: 50rem;
        .img-wrapper {
          position: relative;
            img {width: 100%; height: auto;}
        }
      }
    }
  }
  .glide__arrows {
    display: flex;
    width: 100%;
    top: 0;
    .glide__arrow {
        display: flex;
        @include flex-center;
        background-color: $green;
        border: none;
        position: absolute;
        @include main-tr;
        &-m {height: $s-40; width: $s-40; padding: $s-10; border-radius: $s-10;}
        &-l {height: $s-60; width: $s-60; padding: $s-10; border-radius: $s-12;}
        &:hover {
          box-shadow: $shadow-small;
        }
    }
    .glide__arrow--left {
        left: -$s-10;
    }
    .glide__arrow--right {
      right: -$s-10;
    }
  }
}
.glide__bullets {
  display: flex;
  @include flex-center;
  position: relative;
  margin-top: $s-20;
  .glide__bullet {
    display: inline-block;
    background-color: $light;
    height: $s-10;
    width: $s-10;
    margin-right: $s-20;
    border-radius: $s-20;
    box-shadow: none;
    border: none;
    &:hover {
      background-color: $secondary;
    }
  }
  .glide__bullet--active {
    background-color: $green;
    border: none;
  }
}

@media screen and (max-width: $tablet-landscape-min) {
  
  .glide {
    .glide__bullets {
      .glide__bullet {
        margin-right: $s-10;
      }
    }
    .glide__track {
      .glide__slides {
        .glide__slide {
          max-height: 55rem;
          .img-wrapper {
            position: relative;
            img {
              // height: 100%;
              // width: auto;
            }
          }
        }
      }
    }
  }
}
</style>
