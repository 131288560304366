<template>
  <transition name="fade">
      <div class="hidden-menu" v-if="menuState === true">
        <nav class="nav">
          <router-link class="nav_link router-link-active"
          v-for="navs in navItems"
          :key="navs.name"
          :to="navs.link"
          @click="$store.commit('menuOpen')"
          >
          {{navs.name}}
          </router-link>
        </nav>
        <navBtn :path="'/contacts'" @click="[$store.commit('menuOpen'), $router.push('/contacts')]">Свяжитесь с нами</navBtn>
      </div>
    </transition>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name: 'HiddenMenu',
    computed: {
        ...mapState ({
            menuState: state => state.header.menuState,
            navItems: state => state.header.navItems,
        }),
    },
}
</script>

<style lang="scss" scoped>
    .fade-enter-from{transform: translateX(100%);}
    .fade-leave-to{transform: translateX(100%);}
    .hidden-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      right: 0;
      position: fixed;
      background-color: #f5f5f5;
      height: 100%;
      width: 100%;
      padding: 2rem;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
      transition: $secondary-transition;

      z-index: 50;
      .nav {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        .nav_link {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        .router-link-exact-active {
          font-weight: 800;
        }
        }
    }
</style>