<template>
    <path d="M2 10 17 10"/>
    <path d="M13 5 17 10 13 15"/>
</template>

<script>
export default {
  name: "iArrowLeft",
};
</script>
