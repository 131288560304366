<template>
  <section id="contacts">
    <p class="t-3 t-w-sb mr-b-20 t-center mr-t-40">Свяжитесь с нами</p>
    <p class="t-5 t-c-sc ff-alter t-center t-w-sb mr-b-40">
      Мы всегда открыты к сотрудничеству
    </p>
    <div class="wrapper mr-b-80">
      <div class="work bg-white pd-fr-20 br-r-20">
        <p class="p-l t-c-pr t-w-sb mr-b-10">Трудоустройсто :</p>
        <Link Icon :href="'mailto:hr@truepositive.ru'">
          hr@truepositive.ru <template #icons><iArrowRightM /></template>
        </Link>
      </div>
      <div class="cooperation bg-white pd-fr-20 br-r-20">
        <p class="p-l t-c-pr t-w-sb mr-b-10">Сотрудничество :</p>
        <Link Icon :href="'mailto:info@truepositive.ru'"
          >info@truepositive.ru <template #icons><iArrowRightM /></template>
        </Link>
      </div>
      <div class="adress bg-white pd-fr-20 br-r-20">
        <p class="p-2 t-c-sc t-w-m mr-b-10">Наш адрес:</p>
        <Link
          :href="'https://goo.gl/maps/XWyLVEMTgi4r2MF49'"
          Icon
          Color="secondary"
          class="mr-b-10"
        >
          197374, Санкт-Петербург, Приморский пр., 62-1-1
          <template #icons><iArrowRightM /></template>
        </Link>
        <iframe
          class="br-r-12 mr-t-auto"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d760.7872198327441!2d30.225808557403965!3d59.982242049547615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4696368e2c0014f1%3A0xabec475790243881!2z0J_RgNC40LzQvtGA0YHQutC40Lkg0L_RgC4sIDYy0LoxLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0KDQvtGB0YHQuNGPLCAxOTczNzQ!5e0!3m2!1sru!2sge!4v1650359127543!5m2!1sru!2sge"
          width="auto"
          height="280"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </section>
  <section id="product-contacts">
    <iconBase iconName="iSearch"><iSearch /></iconBase>
    <p class="t-3 t-w-sb t-c-pr t-center ff-alter mr-b-40">
      Есть вопросы по нашим продуктам?
    </p>
    <div class="wrapper mr-b-40">
      <div class="g-4 bg-light br-r-20 pd-fr-20">
        <div class="flex flex-">
          <img class="mr-r-15 m-h-40" src="@/assets/icons/logo/Lab.svg" alt="logo">
          <p class="t-5 t-w-sb t-c-pr">Офис</p>
        </div>
        <div class="border mr-b-20"></div>
        <span class="p-l t-c-sc mr-b-10">Документы и закупки:</span>
          <p class="mr-b-10 p-1 t-w-sb t-c-pr">Елизавета:</p>
          <Link :href="'mailto:office@rerotor.ru'" :Color="'secondary'" class="mr-b-10">office@rerotor.ru</Link>
          <Link :href="'tel:+7(900) 644-67-84'" :Color="'secondary'" class="mr-b-20">+7(900) 644-67-84</Link>
        <div class="border mr-b-20"></div>
        <span class="p-l t-c-sc mr-b-10">Бухгалтерия и GR:</span>
        <p class="mr-b-10 p-1 t-w-sb t-c-pr">Татьяна:</p>
        <Link :href="'mailto:tatu@rerotor.ru'" :Color="'secondary'" class="mr-b-10">tatu@rerotor.ru</Link>
        <Link :href="'tel:+7(911) 297-55-59'" :Color="'secondary'" class="mr-b-20">+7(911) 297-55-59</Link>
        <div class="border mr-b-20"></div>
        <span class="p-l t-c-sc mr-b-10">Генеральный директор:</span>
        <p class="mr-b-10 p-1 t-w-sb t-c-pr">Дмитрий Ивченко:</p>
        <Link :href="'mailto:divchenko@rerotor.ru'" :Color="'secondary'" class="mr-b-10">divchenko@rerotor.ru</Link>
        <Link :href="'tel:+7(921) 948-49-26'" :Color="'secondary'" class="mr-b-20">+7(921) 948-49-26</Link>
      </div>
      <div
        v-for="item in productContacts"
        :key="item"
        class="g-4 bg-light br-r-20 pd-fr-20"
      >
        <div class="flex flex-row">
          <img
            class="mr-r-15"
            :src="require(`@/assets/${item.logo}`)"
            alt="logo"
          />
          <p class="t-5 t-w-sb t-c-pr">{{ item.name }}</p>
        </div>
        <div class="border mr-b-20"></div>
        <span class="p-l t-c-sc mr-b-10">По всем вопросам:</span>
        <!-- Straight Contacst  -->
        <div v-if="item.firstContactName">
          <p class="mr-b-10 p-1 t-w-sb t-c-sc">{{ item.firstContactName }}</p>
          <Link
            :href="`tel:${item.firstContactPhove}`"
            Color="secondary"
            class="mr-b-20"
            >{{ item.firstContactPhove }}</Link
          >
          <p class="mr-b-10 p-1 t-w-sb t-c-sc">{{ item.secondContactName }}</p>
          <Link
            :href="`tel:${item.secondContactPhove}`"
            Color="secondary"
            class="mr-b-20"
            >{{ item.secondContactPhove }}</Link
          >
        </div>
        <!-- Straight Contacst  -->
        <Link
          :href="`mailto:${item.mailLink}`"
          Color="secondary"
          class="mr-b-10"
          v-if="item.mailLink"
          >{{ item.mailLink }}</Link
        >
        <Link
          :href="`tel:${item.phoneLink}`"
          Color="secondary"
          class="mr-b-10"
          v-if="item.phoneLink"
          >{{ item.phoneLink }}</Link
        >
        <Link :href="item.vkLink" Color="secondary" v-if="item.vkLink"
          >Напишите нам Вконтакте</Link
        >
        <div class="border mr-b-20" v-if="supportMail"></div>
        <!-- first border -->
        <span class="p-l t-c-sc mr-b-10" v-if="item.supportMail"
          >Техническая поддержка:
        </span>
        <Link
          :href="`mailto:${item.supportMail}`"
          Color="secondary"
          class="mr-b-10"
          >{{ item.supportMail }}</Link
        >
        <Link
          :href="`tel:${item.supportPhohe}`"
          Color="secondary"
          class="mr-b-10"
          >{{ item.supportPhohe }}</Link
        >
        <div class="border mr-b-10"></div>
        <!-- second border -->
        <span class="p-l t-c-sc mr-b-10" v-if="item.docMail">Документы: </span>
        <Link
          :href="`mailto:${item.docMail}`"
          Color="secondary"
          class="mr-b-10"
          >{{ item.docMail }}</Link
        >
        <Link
          :href="`tel:${item.docPhone}`"
          Color="secondary"
          class="mr-b-10"
          >{{ item.docPhone }}</Link
        >
        <div class="border mr-b-10" v-if="item.docMail"></div>
        <Link :href="`http://${item.siteLink}`">{{ item.siteLink }}</Link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      productContacts: [
        {
          name: "Retail Rotor",
          logo: "logos/rerotor.svg",
          mailLink: "info@rerotor.ru",
          phoneLink: "+7(921) 912-36-10",
          siteLink: "rerotor.ru",
          supportMail: "support@rerotor.ru",
          supportPhohe: "+7(900) 644-67-61",
          docMail: "office@rerotor.ru",
          docPhone: "+7(900) 644-67-84",
        },
        {
          name: "KnotInspector",
          logo: "logos/Knot.svg",
          firstContactName: "Павел Озеров:",
          firstContactPhove: "+7(921) 771-04-78",
          secondContactName: "Сергей Раков:",
          secondContactPhove: "+7(911) 911-18-43",
          siteLink: "knotinspector.ru",
        },
        {
          name: "LogHouse",
          logo: "logos/log-house.svg",
          mailLink: "info@loghouse.pro",
          phoneLink: "+7(911) 911-18-43",
          siteLink: "loghouse.pro",
        },
        {
          name: "ПоЗаписи",
          logo: "logos/pozapisi.svg",
          mailLink: "info@pozapisi.ru",
          vkLink: "https://vk.com/write189597324",
          siteLink: "pozapisi.ru",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#contacts {
  .wrapper {
    grid-template-areas:
      ". work work work work adress adress adress adress adress adress"
      ". cooperation cooperation cooperation cooperation  adress adress adress adress adress adress";
    .work {
      grid-area: work;
    }
    .cooperation {
      grid-area: cooperation;
    }
    .adress {
      grid-area: adress;
    }
  }
}
#product-contacts {
  .wrapper {
    .g-4 {
      .border {
        display: flex;
        width: 100%;
        height: 1px;
        background-color: $light;
      }
      .flex {
        align-items: center;
        padding-bottom: $s-20;
        border-bottom: 1px solid $light;
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape) {
  #contacts {
    .wrapper {
      grid-template-columns: repeat(10, 1fr);
      grid-template-areas:
        "work work work work work cooperation cooperation cooperation cooperation cooperation"
        "adress adress adress adress adress adress adress adress adress adress";
      .work {
        grid-area: work;
      }
      .cooperation {
        grid-area: cooperation;
      }
      .adress {
        grid-area: adress;
      }
    }
  }
  #product-contacts {
    .wrapper {
      grid-template-columns: repeat(9, 1fr);
      .g-4 {
        grid-column-end: span 3;
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-min) {
  #contacts {
    .wrapper {
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
        "work work work work cooperation cooperation cooperation cooperation"
        "adress adress adress adress adress adress adress adress";
    }
  }
  #product-contacts {
    .wrapper {
      grid-template-columns: repeat(8, 1fr);
      .g-4 {
        grid-column-end: span 4;
      }
    }
  }
}

@media screen and (max-width: $tablet-portrait) {
}

@media screen and (max-width: $phone-landscape) {
  #contacts {
    .wrapper {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "work work work"
        "cooperation cooperation cooperation"
        "adress adress adress";
    }
  }
  #product-contacts {
    .wrapper {
      grid-template-columns: repeat(3, 1fr);
      .g-4 {
        grid-column-end: span 3;
      }
    }
  }
}

@media screen and (max-width: $phone-portrait) {
  #contacts {
    .wrapper {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "work work work"
        "cooperation cooperation cooperation"
        "adress adress adress";
    }
  }
  #product-contacts {
    .wrapper {
      grid-template-columns: repeat(3, 1fr);
      .g-4 {
        grid-column-end: span 3;
      }
    }
  }
}

@media screen and (max-width: $flip-phone) {
}
</style>
