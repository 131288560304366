<template>
    <sHeroFlex class="mr-b-80" :heroImage="actualProduct.heroImage">
        <template #Title>{{actualProduct.name}}</template>
        <template #afterTitile>
            <div class="flex flex-row mr-b-20">
                <p class="p-l t-c-sc bg-light-0 pd-fr-8 br-r-20 mr-r-10"> {{actualProduct.version}} </p>
                <p class="p-l t-c-sc bg-light-0 pd-fr-8 br-r-20 mr-r-10"> {{actualProduct.area}} </p>
                <p class="p-l t-c-sc bg-light-0 pd-fr-8 br-r-20 mr-r-10"> {{actualProduct.years}} </p>
            </div>
        </template>
        <template #Paragraph> {{actualProduct.description}} </template>
        <template #afterParagraph>
            <div class="flex flex-row mr-t-20">
                <Btn class="mr-r-20" Blank :href="actualProduct.link" Size="m" >Сайт</Btn> 
                <Btn v-if="actualProduct.contactLink" class="mr-r-20" :href="'http://rerotor.ru/sales'" Size="m" Color="light" >Отдел продаж</Btn> 
            </div>
        </template>
    </sHeroFlex>
    <sColumn-3 :colItems="actualProduct.tips"/>
    <sClients/>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'Страница продукта',
    computed: {
        ...mapGetters ([
            "getProducts"
        ]),
        actualProduct() {
            let result = {};
            let vm = this;
            this.getProducts.map(function (item) {
                if (item.id === vm.$route.params.id) {
                    result = item
                    console.log(result)
                }
            })
            return result;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>