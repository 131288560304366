export const Footer = {
    state: () => ({
        navItems: {
            Ru: [
                'Компания', 
                {name: 'О компании', link: '/about'},
                {name: 'Продукты', link: '/products'},
                {name: 'Технологии', link: '/products/#technology'},
                {name: 'Кейсы', link: '/products/#cases'},
            ],
            En: [
                'Company', 
                {name: 'About', link: '/about'},
                {name: 'Products', link: '/products'},
                {name: 'Technologies', link: '/products/#technology'},
                {name: 'Cases', link: '/products/#cases'},
            ]
        },
        lang: ['En', 'Ru']
    }),
    mutations: {
        langChang(state) {
            state.lang
        }
    }
}