<template>
  <section id="history">
    <p class="t-3 t-c-pr t-w-sb ff-alter mr-b-40 t-center">История компании</p>
    <div class="history-nav flex flex-row mr-b-40">
      <div
        class="history-button"
        v-for="historyItem in getHistory"
        :key="historyItem"
        @click="selectYear = selected = historyItem.year"
      >
        <div class="history-indicator flex flex-row mr-b-10">
          <div class="line"></div>
          <div
            class="indicator br-r-6"
            :class="{ 'indicator-active': historyItem.year === selected }"
          ></div>
          <div class="line"></div>
        </div>
        <span class="history-year p-2 t-c-pr">{{ historyItem.year }}</span>
      </div>
    </div>
    <div
      class="wrapper"
      v-for="events in getHistory"
      :key="events.year"
      :class="{ 'wrapper-active': events.year === selected }"
    >
      <div
        class="br-r-20 pd-fr-20 mr-b-40"
        v-for="event in events.events"
        :key="event"
        :class="event.class"
      >
        <p class="t-5 t-w-sb mr-b-10">{{ event.name }}</p>
        <img
          class="br-r-12 image-wrapper"
          :class="[{ 'mr-b-10': event.description }, 'mr-t-auto']"
          :src="require(`@/assets/${event.img}`)"
          alt=""
          v-if="event.img"
        />
        <p class="p-2 t-c-sc ff-alter" v-if="event.description">
          {{ event.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "sHistory",
  computed: {
    ...mapGetters ([
      'getHistory'
    ])
  },
  data() {
    return {
      selected: "1999",
    };
  },
};
</script>

<style lang="scss" scoped>
#history {
  padding-top: $s-40;
  padding-bottom: $s-40;
  .flex-row {
    justify-content: space-between;
  }
  .history-nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .history-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      flex-grow: 1;
      min-width: $s-60;
      .flex-row {
        flex-wrap: nowrap;
        align-items: center;
      }
      .history-indicator {
        min-width: $s-40;
        width: 100%;
        .line {
          display: flex;
          height: $s-4;
          background-color: $bg-green;
          min-width: $s-6;
          width: 100%;
        }
        .indicator {
          display: block;
          height: $s-20;
          width: $s-20;
          min-height: $s-20;
          min-width: $s-20;
          background-color: $primary;
          transition: $main-transition;
          &-active {
            background-color: $green;
          }
        }
      }
      &:hover {
        .indicator {
          background-color: $green;
          opacity: 0.7;
        }
      }
    }
  }
  .wrapper {
    visibility: hidden;
    display: none;
    min-height: 30vh;
    > *{margin-bottom: 0;}
    &-active {
      visibility: visible;
      display: grid;
      .image-wrapper {
        max-height: 40rem;
        &-30 {
          max-height: 10rem;
          width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape) {
  #history {
    .wrapper {
      grid-template-columns: repeat(10, 1fr);
      grid-auto-columns: dense;
      min-height: 20vh;
      .g-2 {grid-column-end: span 5;}
      .g-3 {
        grid-column-end: span 5;
      }
      .g-4 {grid-column-end: span 5;}
      .g-5 {
        grid-column-end: span 5;
        &:last-child{grid-column-end: span 10;}
      }
      .g-6 {
        grid-column-end: span 6;
        &:last-child{grid-column-end: span 4;}
      }
      .g-12 {grid-column-end: span 10;}
    }
  }
}

@media screen and (max-width: $tablet-landscape-min) {
  #history {
      .history-nav {
          .history-button {
              &:hover {
                  .indicator {
                      background-color: $green;
                  }
              }
          }
      }
    .wrapper {
      grid-template-columns: repeat(8, 1fr);
      min-height: 10vh;
      .g-2 {grid-column-end: span 4;}
      .g-3 {grid-column-end: span 4;}
      .g-4 {grid-column-end: span 4;}
      .g-5 {grid-column-end: span 4;}
      .g-6 {grid-column-end: span 4;}
      .g-12 {grid-column-end: span 8;}
    }
  }
}

@media screen and (max-width: $phone-portrait) {
  #history {
    .wrapper {
      grid-template-columns: repeat(8, 1fr);
      .g-2  {grid-column-end: span 8;}
      .g-3  {grid-column-end: span 8;}
      .g-4  {grid-column-end: span 8;}
      .g-5  {grid-column-end: span 8;}
      .g-6  {
        grid-column-end: span 8;
        &:last-child{grid-column-end: span 8;}
      }
      .g-12 {grid-column-end: span 8;}
    }
  }
}
</style>
