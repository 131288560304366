
<template>
  <section id="partners">
      <p class="t-3 t-w-sb ff-alter t-center mr-b-40">Наши партнеры</p>
      <div class="wrapper mr-b-40" v-if="withLink === false">
          <div class="g-2 bg-white img-wrapper flex br-r-20" v-for="item in Items" :key="item"> 
              <div class="img-wrapper">
                  <img :src="require(`@/assets/${item.img}`)" :alt="item.alt"> 
              </div>
          </div>
      </div>
      <div class="wrapper mr-b-40" v-if="withLink === true">
          <a :href="item.link" target="_blank" class="g-2 bg-white flex br-r-20 interactive-white" v-for="item in Items" :key="item"> 
              <div class="img-wrapper">
                  <img :src="require(`@/assets/${item.img}`)" :alt="item.alt"> 
              </div>
          </a>
      </div>
      <p v-if="onPage === true" class="p-2 t-c-sc t-w-m ff-alter t-center mr-b-20">Мы всегда открыты к сотрудничеству и новым предложениям. <br>Если вам есть, что нам предложить, – welcome!</p>
      <Btn v-if="onPage === true" Center path="/cooperation" >Сотрудничество</Btn>
  </section>
</template>

<script>
export default {
    name: 'sPartners',
    props: {
        onPage: {
            type: Boolean,
            default: true
        },
        Items: {
            type: Array
        },
        withLink: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss" scoped>
    #partners {
        padding-top: $s-40;
        padding-bottom: $s-40;
        .wrapper {
            a {cursor: pointer;}
            .g-2 {
                .img-wrapper {
                    display: flex;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    align-content: center;
                    justify-content: center;
                    padding: $s-20;
                    max-height: $s-120;
                        img {
                            height: auto;
                            width: 100%;
                            max-width: $s-120;
                            -webkit-filter: grayscale(100%);
                            @include main-tr;
                            &:hover {
                                -webkit-filter: grayscale(0%);
                            }
                            }
                    }
                &:nth-child(1) {
                    grid-column-start: 3;
                }
                &:nth-child(5) {
                    grid-column-start: 3;
                }
            }
        }
    }

@media screen and (max-width: $tablet-landscape) {
    #partners {
        .wrapper {
            grid-template-columns: repeat(9, 1fr);
            .g-2 {
                grid-column-end: span 3;
                &:nth-child(1) {
                    grid-column-start: 1;
                }
                &:nth-child(5) {
                    grid-column-start: 4;
                }
            }
        }
    }
}

@media screen and (max-width: $phone-portrait) {
    #partners {
        .wrapper {
            grid-template-columns: repeat(8, 1fr);
            .g-2 {
                grid-column-end: span 4;
                &:nth-child(1) {
                    grid-column-start: 1;
                }
                &:nth-child(5) {
                    grid-column-start: 1;
                }
            }
        }
    }
}
</style>