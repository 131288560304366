<template>
  <section id="products" :class="topMargin">
      <div class="wrapper">
          <div class="g-4 br-r-20 pd-fr-20 bg-white">
              <div class="flex">
                <p class="t-3 t-w-sb mr-b-20 t-lh-13">Наши <br> продукты</p>
                <img class="droid-pic" src="~@/assets/img/droid-1.svg" alt="droid">
              </div>
              <p class="p-2 t-c-sc mr-b-auto t-lh-15">За годы работы мы внесли вклад в разные сферы рынка и поработали с федеральным ритейлом, деревообработкой, крупным и малым бизнесом.</p>
              <btn v-if="bottonOn === true" Size="m" :path="'/products'" class="mr-t-40">Узнать больше</btn>
          </div>
          <ProductCard v-for="card in $store.state.products.products" :key="card.name" :Item="card"/>
      </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'sProducts',
    computed: {
        ...mapGetters ([
            'getProducts'
        ])
    },
    props: {
        bottonOn: {
            type: Boolean,
            default: true
        },
        topMargin: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    #products {
        padding-top: $s-40;
        padding-bottom: $s-40;
    }
    .flex {
        flex-direction: row;
        position: relative;
        .droid-pic {
            position: absolute;
            top: -50%;
            left: 60%;
        }
    }

    @media screen and (max-width: $tablet-landscape) {
        .wrapper {
            grid-template-columns: repeat(9, 1fr);
            .g-8 {
               grid-column-end: span 6;
           }
           .g-4{
               grid-column-end: span 3;
           }
           .droid-pic {
                left: 50%;
                top: -65%;
            }
           
        }
    }
    @media screen and (max-width: $tablet-landscape-min){
        .wrapper {
            grid-template-columns: repeat(8, 1fr);
            .g-8, .g-4 {
                grid-column-end: span 4;
            }
        }
    }  
    @media screen and (max-width: $tablet-portrait){
        .wrapper {
            grid-template-columns: repeat(6, 1fr);
            .g-8, .g-4 {
                grid-column-end: span 6;
                min-height: fit-content;
                .illustration {
                    width: fit-content;
                    align-self: center;
                }
            }
            .droid-pic {
                display: none;
            }
        }
    } 
    
    @media screen and (max-width: $phone-portrait) {
        .wrapper {
            .g-4 {
                .illustration {
                    width: 100%;
                }
            }
        }
    }
</style>