<template>
  <section id="mission">
    <div class="wrapper">
      <div class="g-10">
        <p class="t-2 t-w-b t-c-w t-center mr-b-20">Наша миссия</p>
        <p class="t-4 t-lh-15 t-c-w ff-alter t-center">
          Отдать роботам скучную работу,<br />
          а людям – интересную.
        </p>
        <div class="mask"></div>
      </div>
    </div>
    <div class="link link-1"><img src="~@/assets/img/products/1.png" /></div>
    <div class="link link-2"><img src="~@/assets/img/products/2.png" /></div>
    <!-- <div class="link link-3"><img src="~@/assets/img/products/3.png" /></div> -->
    <div class="link link-4">
      <img src="~@/assets/illustrations/Cooperation/2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  name: "sMission",
};
</script>

<style lang="scss" scoped>
#mission {
  padding-top: $s-80;
  padding-bottom: $s-120;
  position: relative;
  .link {
    position: absolute;
    &-1 {
      top: -10%;
      left: 10%;
      transform: scale(0.5);
    }
    &-2 {
      top: 5%;
      right: -10%;
      transform: scale(0.5);
    }
    &-3 {
      bottom: -10%;
      left: -10%;
      transform: scale(0.5);
    }
    &-4 {
      bottom: 0%;
      right: 40%;
      transform: scale(1.25);
    }
  }
  .wrapper {
    .g-10 {
      grid-column-start: 2;
      position: relative;
      padding: $s-120 0 $s-120;
      .mask {
        display: block;
        position: absolute;
        height: 120%;
        width: 110%;
        top: -10%;
        left: -5%;
        z-index: -1;
        background: url("~@/assets/masks/mask-bg.png") no-repeat center / 100%
          100%;
      }
    }
  }
}
@media screen and (max-width: $tablet-landscape) {
  #mission {
       .link {
            &-1{top: -5%;}
            &-2{top: -5%;}
        }
    .wrapper {
      grid-template-columns: repeat(9, 1fr);
      .g-10 {
        grid-column-start: 1;
        grid-column-end: span 9;
        .mask {
          width: 100%;
          left: 0;
          
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-min) {
  #mission {
    .link {
      &-1 {left: 0%;}
      &-2 {right: -15%; top: -20%;}
      &-3 {left: -15%;}
      &-4 {right: 0%; bottom: 5%;}
    }
  }
}

@media screen and (max-width: $tablet-portrait) {
  #mission {
      padding-top: $s-80;
      padding-bottom: $s-80;
    .link {
      &-1 {left: 20%; top: -10%;}
      &-2 {display: none;}
      &-3 {left: -18%; bottom: -15%;}
      &-4 {right: 0%; bottom: 6%;}
    }
    .wrapper {
      .g-10 {
        .mask {
          background: url("~@/assets/masks/mask-bg-2.png") no-repeat center / 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $phone-landscape) {
  #mission {
    .link {
      &-1 {left: 10%; top: -10%;}
      &-3 {display: none;}
      &-4 {right: 0%; bottom: 0%;}
    }
    .wrapper {
      .g-10 {
        .mask {
          background: url("~@/assets/masks/mask-bg-3.png") no-repeat center / 100% 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $phone-portrait) {
  #mission {
    .link {
      &-4 {right: 20%}
    }
  }
}
</style>
