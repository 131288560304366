<template>
    <path
      d="M16,3.75H4a2,2,0,0,0-2,2v8.5a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V5.75A2,2,0,0,0,16,3.75Zm0,10.5H4V9.7l4.59,3.07a2.53,2.53,0,0,0,2.82,0L16,9.7ZM16,7.3l-5.7,3.8a.51.51,0,0,1-.6,0L4,7.3V5.75H16Z"
    />
</template>

<script>
export default {
  name: "iMailM",
};
</script>
