<template>
  <div class="wrapper mr-t-20 mr-b-20">
      <div 
      class="t-center g-4 flex"
      :class="item.class"
      v-for="item in colItems"
      :key="item.title"
      >
      <div class="img-wrapper"><img class="mr-b-15" :src="require(`@/assets/${item.icon}`)"></div>
      <p class="t-5 t-w-sb mr-b-15">{{item.title}}</p>
      <p class="p-2 t-w-m t-c-sc t-lh-15">{{item.text}}</p>
      <div class="mask" :class="item.mask"></div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'sColumn3',
    props: {
        colItems: {
            type: Array,
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrapper{
        padding-top: $s-20;
        padding-bottom: $s-20;
        .g-4 {
            padding: $s-40 $s-20;
            .mask {
                display: block;
                position: absolute;
                top: -5%;
                left: -2%;
                width: 104%;
                height: 110%;
                z-index: -1;
                &-left {
                    background: url('~@/assets/masks/mask-left.png') no-repeat center/ 100% 100%;    
                }
                &-center {
                    background: url('~@/assets/masks/mask-center.png') no-repeat center/ 100% 100%;    
                }
                &-right {
                    background: url('~@/assets/masks/mask-right.png') no-repeat center/ 100% 100%;    
                }
            }
        }
        
    }
    @media screen and (max-width: $tablet-landscape) {
        .wrapper {
            grid-template-columns: repeat(9 ,1fr);
            .g-4 {
                grid-column-end: span 3;
            }
        }
    }

    @media screen and (max-width: $tablet-landscape) {
        .wrapper {
            grid-template-columns: repeat(3, 1fr);
            .g-4 {
                grid-column-end: span 3;
            }
        }
    }

    @media screen and (max-width: $phone-portrait) {
        .wrapper {
            .g-4 {
                margin-bottom: $s-20;
                &:last-child {
                    margin-bottom: 0;
                }
                .mask {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>