<template>
  <div class="wrapper">
    <div class="g-7">
      <Gallery :images="galleryItems" galleryName="office" :Pagination="false"/>
    </div>
    <div class="g-5">
      <p class="t-3 t-w-sb mr-b-20"><slot name="Title"/></p>
      <p class="p-2 t-c-sc t-lh-15 ff-alter t-w-m"><slot name="Paragraph"/></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'sHeroGallery',
  props: {
    galleryItems: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: $s-40;
  padding-bottom: $s-40;
  .g-5 {
    justify-content: center;
  }
}
@media screen and (max-width: $tablet-landscape) {
  .wrapper {
    grid-template-columns: repeat(10, 1fr);
    .g-5 {
      grid-column-end: span 4;
    }
    .g-7 {
      grid-column-end: span 6;
    }
  }
}
@media screen and (max-width: $tablet-landscape-min) {
  .wrapper {
    grid-template-columns: repeat(9, 1fr);
    .g-5 {
      grid-column-end: span 9;
    }
    .g-7 {
      grid-column-end: span 9;
    }
  }
}
</style>
