<template>
    <path
      d="M8.36,12.64a1,1,0,0,1-.71-.29,3.49,3.49,0,0,1,0-4.95L12,3A3.48,3.48,0,0,1,17,3,3.42,3.42,0,0,1,18,5.5,3.46,3.46,0,0,1,17,8L15.24,9.71a1,1,0,0,1-1.41,0,1,1,0,0,1,0-1.42l1.73-1.73a1.5,1.5,0,0,0-2.12-2.12L9.06,8.81a1.51,1.51,0,0,0,0,2.13,1,1,0,0,1,0,1.41A1,1,0,0,1,8.36,12.64Z"
    />
    <path
      d="M5.5,18A3.44,3.44,0,0,1,3,17,3.42,3.42,0,0,1,2,14.5,3.46,3.46,0,0,1,3,12l1.73-1.73a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L4.44,13.44a1.5,1.5,0,0,0,2.12,2.12L11,11.08A1.47,1.47,0,0,0,11.48,10,1.46,1.46,0,0,0,11,9a1,1,0,1,1,1.42-1.42,3.51,3.51,0,0,1,0,5L8,17A3.44,3.44,0,0,1,5.5,18Z"
    />
</template>

<script>
export default {
    name: 'iLinkM',
};
</script>