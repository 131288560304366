export const Partners = {
    state: () => ({
        partners: [
            {img: 'logos/partners/AlfaGroup.png', alt: 'Наш партнер', description: '', link: 'http://www.gruppa-alfa.ru/'},
            {img: 'logos/partners/AndroidMag.png', alt: 'Наш партнер', description: '', link: 'https://androidmag.ru/'},
            {img: 'logos/partners/PSDevelopment.png', alt: 'Наш партнер', description: '', link: 'http://ps-development.ru/'},
            {img: 'logos/partners/RedLine.png', alt: 'Наш партнер', description: '', link: 'https://redline-cctv.ru/'},
            {img: 'logos/partners/SFT.png', alt: 'Наш партнер', description: '', link: 'https://www.sft.ru/'},
            {img: 'logos/partners/Trakt.png', alt: 'Наш партнер', description: '', link: 'https://www.tract.ru/'},
            {img: 'logos/partners/Techno.png', alt: 'Наш партнер', description: '', link: 'http://www.tcmp.ru/'},
            {img: 'logos/partners/ЭлектронныеДеньги.png', alt: 'Наш партнер', description: '', link: 'https://dengy.ru/'},
        ]
    }),
    getters: {
        getPartners(state) {
            return state.partners
        }
    }
}