export const Header = {
    state: () => ({
        navItems: [
            {name: "Главная", link: "/"},
            {name: "О компании", link: "/about"},
            {name: "Продукты", link: "/products"},
            {name: "Сотрудничество", link: "/cooperation"},
            {name: "Работа в компании", link: "/career"},
        ],
        menuState: false,
    }),
    getters: {

    },
    mutations: {
        menuOpen(state) {
            state.menuState = !state.menuState
        }
    },
    actions: {

    }
}