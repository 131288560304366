<template>
  <section id="map">
      
  </section>
</template>

<script>
export default {
    name: 'sMap',

}
</script>

<style lang="scss" scoped>

</style>