<template>
    <div class="pop-up">
        <div class="pop-up-content main_layout pd-fr-40">
        <div class="pop-up-closer btn-white t-c-pr t-w-b" @click="$store.state.popupOpen = !$store.state.popupOpen">X</div>
        <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'popup',
}
</script>

<style lang="scss">
    .popup-enter-from{opacity: 1;}
    .popup-leave-to{opacity: 0;}
    .pop-up {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $secondary;
        padding: 5rem;
        z-index: 101;
        transition: 0.5s;

      .pop-up-content {
        position: relative;
        width: 100%;
        background-color: $bg-light;
        border-radius: $s-20;
        min-height: $s-120;

        .pop-up-closer {
          display: flex;
          justify-content: center;
          position: absolute;
          height: $s-40;
          width: $s-40;
          top: -1rem;
          right: -1rem;
          background-color: $bg-white;
          border-radius: $s-40;
        }
      }
    }
</style>