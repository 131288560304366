<template>
  <circle cx="8.75" cy="8.75" r="6.25" class="cls-1" />
  <path d="M17 17 13.17 13.17" class="cls-2" />
</template>

<script>
export default {
  name: "iSearch",
};
</script>

<style lang="scss" scoped>
.cls-1 {
          fill: none;
        }
        .cls-1,
        .cls-2 {
          stroke: $white;
          stroke-linecap: round;
          stroke-miterlimit: 10;
        }
        .cls-2 {
          fill: none;
        }
</style>
