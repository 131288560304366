<template>
  <section class="hero flex-col" :class="marginBottom" id="hero-main">
      <h1 :class="Size" class="t-w-b t-c-pr mr-b-10 t-lh-13 if-empty"><slot name="Title"></slot></h1>
      <p class="t-4 t-c-sc mr-b-50 t-w-m ff-alter t-lh-15 if-empty"><slot name="Description"></slot></p>
      <p :class="subSize" class="t-c-sc mr-b-20 t-lh-15 if-empty"><slot name="Subtitle"></slot></p>
      <slot name="Button"></slot>
      <slot/>
  </section>
</template>

<script>

export default {
   name: 'sHero',
   props: {
     Size: {
       type: String,
       default: 't-1'
     },
     subSize: {
       type: String,
       default: 'p-2'
     },
     marginBottom: {
       type: String,
       default: 'pd-b-80'
     }
   }
}
</script>

<style lang="scss" scoped>
    .hero {
        text-align: center;
        position: relative;
        padding-top: $s-80;
    }
    @media screen and (max-width: $phone-portrait) {
      .hero {
        padding-top: $s-40;
        padding-bottom: $s-40;
        .mr-b-50 {margin-bottom: $s-20;}
      }
    }
</style>