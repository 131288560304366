<template>
<div class="main-wrapper">
    <Header/>
    <div class="main_layout">
        <router-view/>
    </div>
    <Footer/>
    <Copyrights/>
</div>
</template>

<script>
import Header from '../components/Heder.vue'
import Footer from '../components/Footer.vue'
import Copyrights from '../components/Copyrights.vue'
export default {
    name: 'mainLayout',
    components: {
        Header,
        Footer,
        Copyrights
    }
}
</script>

<style lang="scss" scoped>
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}
</style>