<template>
  <section id="word">
      <p class="t-3 t-w-sb ff-alter t-c-pr t-center mr-b-40">Слово основателя компании</p>
      <div class="wrapper bg-white br-r-20 pd-fr-40">
          <div class="g-6">
              <div class="img-wrapper">
                  <img class="hero-image" src="~@/assets/img/word.png" alt="">
              </div>
          </div>
          <div class="g-6">
              <p class="p-1 t-w-m t-c-sc t-lh-15 mr-b-20">Мы заботимся о клиентах и сотрудниках.<br>  Давайте работать вместе. </p>
              <Btn
              Size="m"
              path="/work"
              class="mr-t-auto"
              >
                Вступайте в команду
              </Btn>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    name: 'sWord',
}
</script>

<style lang="scss" scoped>
    #word {
        padding-top: $s-80; 
        padding-bottom: $s-80;
        .wrapper {
            .g-6 {
                height: 100%;
            .img-wrapper {
                display: flex;
                @include flex-center;
                .hero-image {width: 110%; height: auto; margin: -$s-40} 
            }
        }
        }
    }

    @media screen and (max-width: $tablet-landscape) {
       #word {
           .wrapper {
               grid-template-columns: repeat(10, 1fr);
               .g-6 {grid-column-end: span 5;}
           }
       } 
    }

    @media screen and (max-width: $tablet-landscape-min) {
       #word {
           .wrapper {
               grid-template-columns: repeat(6, 1fr);
               .g-6 {
                   grid-column-end: span 6;
                   .p-1 {margin-top: $s-40;}
                   .img-wrapper {
                       .hero-image {
                           margin-top: -10%;
                           width: 100%;
                       }
                   }
               }
           }
       } 
    }
    @media screen and (max-width: $phone-landscape) {
       #word {
           .wrapper {
               .g-6 {
                   .img-wrapper {
                       .hero-image {
                           width: 110%;
                       }
                   }
               }
           }
       } 
    }
</style>