import sHero from '@/components/sections/sHero.vue';
import sHeroFlex from '@/components/sections/sHeroFlex.vue';
import sColumn3 from '@/components/sections/sColumn-3.vue';
import sMap from '@/components/sections/sMap.vue';

import sProducts from '@/components/sections/sProducts.vue';
import sTeam from '@/components/sections/sTeam.vue';
import sClients from '@/components/sections/sClients.vue';
import sPartners from '@/components/sections/sPartners.vue';
import sHistory from '@/components/sections/sHistory.vue';
import sTechnologies from '@/components/sections/sTechnologies.vue';
import sVacancy from '@/components/sections/sVacancy.vue';
import sWord from '@/components/sections/sWord.vue';
import sValues from '@/components/sections/sValues.vue';
import sMission from '@/components/sections/sMission.vue';
import sHeroGallery from '@/components/sections/sHeroGallery.vue';
import sHeroList from '@/components/sections/sHeroList.vue';
import sHeroTransparent from '@/components/sections/sHeroTransparent.vue';
import sAtention from '@/components/sections/sAtention.vue';
import sHeroIconList from '@/components/sections/sHeroIconList.vue';



export default [
    sHero,
    sHeroFlex,
    sProducts,
    sTeam,
    sClients,
    sPartners,
    sColumn3,
    sMap,
    sHistory,
    sTechnologies,
    sVacancy,
    sWord,
    sValues,
    sMission,
    sHeroGallery,
    sHeroList,
    sHeroTransparent,
    sAtention,
    sHeroIconList
]