<template>
  <section id="gallery">
    <div class="flex flex-row flex-just-center">
      <!-- @click="openImage(item, index)" -->
      <a
        :href="item.href"
        target="_blank"
        class="g-3 gallery-item pd-fr-20 br-r-20 bg-light interactive"
        v-for="(item, index) in Images"
        :key="index"
      >
        <img :src="require(`@/assets/${item.img}`)" :alt="item.alt" />
      </a>
    </div>
  </section>
  <transition name="fade">
    <section v-if="gappelyPopup === true" id="popup">
      <div @click="closePopup" class="cross">
        <div></div>
        <div></div>
      </div>
      <div class="popup-image flex flex-col">
        <img
          :src="require(`@/assets/${currentImage.img}`)"
          :alt="currentImage.alt"
        />
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: "ImageGallery",
  props: {
    Images: {
      type: Array,
    },
    grid: {
      type: [Boolean, String],
      default: "g-3",
    },
    href: {
      type: String,
    }
  },
  methods: {
    openImage(item, index) {
      this.gappelyPopup = !this.gappelyPopup;
      (this.currentImage = item), index;
    },
    closePopup() {
      this.gappelyPopup = !this.gappelyPopup;
    },
  },
  data() {
    return {
      gappelyPopup: false,
      currentImage: "",
    };
  },
};
</script>

<style lang="scss" scopeds>
#gallery {
  padding: $s-20 0;
  .flex {
    align-items: center;
    .gallery-item {
      display: flex;
      @include flex-center;
      cursor: pointer;
      height: 40rem;
      position: relative;
      margin-bottom: $s-40;
      &:last-child {
        margin-right: 0;
      }
      img {
        height: 100%;
        border-radius: $s-12;
      }
    }
  }
}
#popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: $secondary;
  padding: $s-40;
  display: flex;
  flex-direction: column;
  @include flex-center;
  z-index: 10;
  .cross {
    display: block;
    background-color: $white;
    height: $s-40;
    width: $s-40;
    position: absolute;
    top: $s-40;
    right: $s-40;
    border-radius: $s-100;
    @include main-tr;
    z-index: 100;
    div {
      display: block;
      position: absolute;
      width: 50%;
      height: 2px;
      background-color: $secondary;
      top: 49%;
      left: 26%;
      &:first-child {
        transform: rotate(45deg);
      }
      &:last-child {
        transform: rotate(-45deg);
      }
    }
    &:hover {
      box-shadow: $shadow-small;
    }
  }
  .popup-image {
    display: flex;
    height: 80%;
    max-width: 100%;
    position: relative;
    @include flex-center;
    img {
      height: 100%;
      width: auto;
      border-radius: $s-20;
    }
  }
}

@media screen and (max-width: $tablet-landscape) {
    #gallery {
    .wrapper {
      grid-template-columns: repeat(8, 1fr);
      .gallery-item {
        height: fit-content;
      }
      .g-3 {
        grid-column-end: span 2;
        img {width: 100%; height: auto;}
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-min) {
    #gallery {
    .wrapper {
      grid-template-columns: repeat(6, 1fr);
      .g-3 {
        grid-column-end: span 3;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape) {
  #popup {
    .popup-image {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
