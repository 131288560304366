<template>
  <div class="g-4 bg-light pd-fr-20 br-r-20">
    <div class="flex flex-row flex-center mr-b-20">
      <img class="mr-r-10" :src="require(`@/assets/${Item.logo}`)" alt="logo" />
      <h3 class="t-5 t-w-sb mr-r-auto">{{Item.name}}</h3>
      <p class="p-l t-c-sc t-w-m">{{Item.from}}</p>
    </div>
    <img
      class="mr-b-20 illustration"
      :src="require(`@/assets/${Item.illustration}`)"
      alt="Rerotor"
    />
    <p class="p-2 t-c-sc t-w-r mr-b-20">
      {{Item.description}}
    </p>
    <btn class="mr-t-auto" :href="Item.link" Blank Size="m">Сайт продукта</btn>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    Item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>

@media screen and (max-width: $tablet-portrait) {
  .illustration {
        width: fit-content;
        align-self: center;
    }
}
</style>
