<template>
  <div class="content">
    <sHero>
      <template #Title>ТП Лаб</template>
      <template #Description>Скучную работу – роботам, <br>Интересную работу – людям</template>
      <template #Subtitle><span class="mobile-hidden">Распределенная команда. Лучшие в том, что мы делаем.<br> Растём вместе с клиентами и рынками.</span></template>
      <template #Button>
        <Btn Center Icon="iArrowRightM" Color="green" :path="'/products'">
          Наши продукты
          <template #Icon><iArrowRightM/></template>
        </Btn>
      </template>
           <a @click="$router.push('/tp_kit')" :href="'/tp_kit'" class="pd-fr-20 bg-light br-r-20 flex link link-1 link-hovered">
            <img src="@/assets/icons/links/tpkit.svg">
          </a>
           <a @click="$router.push('/about')" :href="'/about'" class="pd-fr-20 bg-light br-r-20 flex link link-2 link-hovered">
            <img src="@/assets/icons/links/tplab.svg">
          </a>
           <a :href="'http://pozapisi.ru/'" target="_blank" class="pd-fr-20 bg-light br-r-20 flex link link-3 link-hovered">
            <img src="@/assets/icons/links/zapis.svg">
          </a>
          <a :href="'http://fmmon.ru/'" target="_blank" class="pd-fr-20 bg-light br-r-20 flex link link-4 link-hovered">
            <img src="@/assets/icons/links/FmM.svg">
          </a>
          <a :href="'http://loghouse.pro/'" target="_blank" class="pd-fr-20 bg-light br-r-20 flex link link-5 link-hovered">
            <img src="@/assets/icons/links/lh.svg">
          </a>
          <a :href="'http://rerotor.ru'" target="_blank" class="flex link link-6">
            <img src="@/assets/icons/links/rerotor.svg">
          </a>
          <a :href="'http://knotinspector.ru/'" target="_blank" class="flex link link-7">
            <img src="@/assets/icons/links/KI.svg">
          </a>
    </sHero>
    <sColumn3
    :colItems="colItems"
    />
    <sTeam :teamItems="getTeam.slice(0, 11)"/>
    <sProducts :topMargin="'mr-t-80'"/>
    <sClients :Items="$store.getters.getClients"/>
    <sPartners :Items="getPartners"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'ТП Лаб',
    computed: {
      ...mapGetters ([
        'getTeam',
        'getPartners'
      ])
    },
    data() {
        return {
            colItems: [
                {title: '20 лет на рынке', icon: 'icons/blocks/calendar.svg', text: 'От 3.5” до 10 Гбит/с, от Pentuim до M2, от IE 3 до Chrome 100, от MFC до ReactJS, от DSP на ассемблере до PyTorch, всегда на острие технологий', class: 'g-4 bg-mask bg-mask-left', mask: 'mask-left'},
                {title: '5 собственных продуктов', icon: 'icons/blocks/box.svg', text: 'Отвечаем новыми решениями на вызовы 4‑й промышленной революции и поддерживаем старые до конца – LTS для нас не пустой звук.', class: 'g-4 bg-mask bg-mask-center', mask: 'mask-center'},
                {title: '25 настоящих профи', icon: 'icons/blocks/team.svg', text: 'Полный набор компетенций для продуктовой разработки XXI века в дружной команде единомышленников', class: 'g-4 bg-mask bg-mask-right', mask: 'mask-right'},  
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
  .content {
    .flex {
      @include flex-center;
      width: fit-content;
    }
    .link {
      position: absolute;
      cursor: pointer;
      pointer-events: auto;
      transition: $main-transition;
      &-1{
      top: 30%;
      left: 15%;
      height: $s-80;
      width: $s-80;
      z-index: 10;
      }
      &-2 {
        top: 10%;
        left: 25%;
        height: $s-80;
        width: $s-80;
        z-index: 10;
      }
      &-3 {
        bottom: 10%;
        left: 25%;
        height: $s-80;
        width: $s-80;
        z-index: 10;
      }
      &-4 {
        top: 15%;
        right: 25%;
        height: $s-80;
        width: $s-80;
        z-index: 10;
      }
      &-5 {
        bottom: 15%;
        right: 25%;
        height: $s-80;
        width: $s-80;
        z-index: 10;
      }
      &-6 {
        bottom: 10%;
        left: 5%;
         z-index: 5;
         &:hover {
          transform: scale(1.05);
        }
      }
      &-7 {
        top: 15%;
        right: 5%;
        z-index: 5;
        &:hover {
          transform: scale(1.05);
        }
      }
      &-hovered {
        transition: $main-transition;
        &:hover {
          box-shadow: $shadow-light-hover;
        }
      }
    }
  }
  @media screen and (max-width: $tablet-landscape-min) {
    .content {
      .link {
        z-index: -1;
        &-1 {
          left: 10%;
        }
        &-2 {
          top: 5%;
        }
        &-3 {
          bottom: 5%;
        }
        &-4 {
          top: 5%;
        }
        &-5 {
          bottom: 5%;
        }
        &-6 {
          left: -5%;
        }
        &-7 {
          right: -5%;
        }
      }
    }
    @media screen and (max-width: $tablet-landscape-min) {
      .content {
        .link {
          &-1 {display: none;}
          &-2 {top: 25%; left: 5%;}
          &-3 {top: 5%;}
          &-4 {display: none;}
          &-5 {bottom: 10%;right: 10%;}
          &-6 {bottom: 0; left: -10%;}
          &-7 {top: 0;right: -10%;}
        }
      }
    }
    @media screen and (max-width: $tablet-landscape-min) {
      .content {
        .link {
          &-2{top: 20%; left: 10%;}
          &-3{top: 0;}
          &-5 {bottom: 10%;right: 15%;}
          &-6 {bottom: 0; left: 0;}
          &-7 {top: 0;right: 0;}
        }
      }
    }
    @media screen and (max-width: $phone-landscape) {
      .content {
        .mobile-hidden {display: none; visibility: hidden;}
        .link {
          &-2{left: 5%;}
          &-3 {top: 5%;}
          &-5 {right: 5%}
          &-6 {left: -10%;}
          &-7 {right: -10%;}
        }
      }
    }
    @media screen and (max-width: $phone-portrait) {
      .content {
        br {display: none; visibility: hidden;}
        .link {
          &-2 {top: 5%; left: 0%; transform: scale(0.7);}
          &-3 {left: 75%; top: 0; transform: scale(0.7);}
          &-5 {bottom: 0; right: 0%; transform: scale(0.9);}
          &-6{bottom: -35%; left: -20%; transform: scale(0.9); opacity: 0.4;}
          &-7{display: none;}
        }
      }
    }
    @media screen and (min-height: $phone-portrait) and (max-height: $phone-landscape) {
      .content {
        br {display: none; visibility: hidden;}
        .link {
          &-2 {top: 5%; left: 0%; transform: scale(0.7);}
          &-3 {left: 75%; top: 0; transform: scale(0.7);}
          &-5 {bottom: 0; right: 0%; transform: scale(0.9);}
          &-6{bottom: -35%; left: -10%; transform: scale(0.9); opacity: 0.4;}
          &-7{display: none;}
        }
      }
    }
  }
</style>