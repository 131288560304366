<template>
  <div v-if="getVacancy.name">
    <!--vacancy hero-->
    <section id="vacancy-hero">
      <div class="flex flex-col bg-white br-r-20 pd-fr-40">
        <h1 v-if="getVacancy.name" class="t-3 t-w-sb ff-alter mr-b-20">
          {{ getVacancy.name }}
        </h1>
        <p v-if="getVacancy.jobType" class="p-2 t-c-sc t-w-m mr-b-20">
          {{ getVacancy.jobType }}
        </p>
        <div class="flex flex-row mr-b-20" v-if="getVacancy.steck">
          <p
            v-for="item in getVacancy.steck"
            :key="item"
            class="p-l t-c-sc mr-r-10 t-w-m bg-light-0 br-r-100 pd-fr-8"
          >
            {{ item }}
          </p>
        </div>
        <p v-if="getVacancy.about" class="p-2 t-c-sc t-lh-15 mr-b-20">
          {{ getVacancy.about }}
        </p>
        <div class="flex flex-row">
          <Btn v-if="getVacancy.link" :href="getVacancy.link" class="mr-r-20" Size="m" @click="ymGoal">Откликнуться на вакансию</Btn>
          <Btn v-if="getVacancy.testHere" :href="'#vacancy-test'" Size="m" Color="white">Тестовое задание</Btn>
        </div>
      </div>
    </section>
    <!--vacancy advantages-->
    <sHeroIconList :Items="benefits" :id="'benefits'">
      <template #Title>У нас классно</template>
    </sHeroIconList>
    <!--vacancy info-->
    <section id="vacancy-info" class="pd-fr-40 if-empty">
      <p class="t-4 t-c-pr t-w-sb mr-b-20">Требования к вакансии:</p>
      <Ul
        v-if="getVacancy.jobRequirements"
        Size="s"
        :Items="getVacancy.jobRequirements"
      />
    </section>
    <!-- Test 1 -->
    <section
      v-if="getVacancy.test"
      id="vacancy-test"
      class="bg-white br-r-20 pd-fr-40 mr-b-40 if-empty"
    >
      <p v-if="getVacancy.test.testName" class="t-4 t-c-pr t-w-sb"
         :class="[getVacancy.test.testType ? 'mr-b-10' : 'mr-b-20']">
        {{ getVacancy.test.testName }}
      </p>
      <p v-if="getVacancy.test.testType" class="p-l t-c-sc mr-b-20">
        {{ getVacancy.test.testType }}
      </p>
      <p
        v-if="getVacancy.test.testDescription"
        class="p-2 t-c-sc t-lh-15 t-w-m mr-b-30"
      >
        {{ getVacancy.test.testDescription }}
      </p>
      <div class="flex flex-center mr-b-20" v-if="getVacancy.test.testImg">
        <img :src="require(`@/assets/${getVacancy.test.testImg}`)">
      </div>
      <p v-if="getVacancy.test.testRequirementsTitle" class="t-5 t-w-sb t-lh-15 mr-b-20">
        {{ getVacancy.test.testRequirementsTitle }}</p>
      <Ul
        v-if="getVacancy.test.testRequirements"
        Size="s"
        :Items="getVacancy.test.testRequirements"
      />
    </section>
    <!-- Test 2 -->
    <section id="vacancy-test-2" v-if="getVacancy.test.testName_2" class="bg-white br-r-20 pd-fr-40 mr-b-40 if-empty">
      <p v-if="getVacancy.test.testName_2" class="t-4 t-c-pr t-w-sb mr-b-10">
        {{ getVacancy.test.testName_2 }}
      </p>
      <p v-if="getVacancy.test.testType_2" class="p-l t-c-sc mr-b-20">
        {{ getVacancy.test.testType_2 }}
      </p>
      <p
        v-if="getVacancy.test.testDescription_2"
        class="p-2 t-c-sc t-lh-15 t-w-m mr-b-30"
      >
        {{ getVacancy.test.testDescription_2 }}
      </p>
      <div class="flex flex-center mr-b-20" v-if="getVacancy.test.testImg_2">
        <img :src="require(`@/assets/${getVacancy.test.testImg_2}`)">
      </div>
      <p v-if="getVacancy.test.testRequirementsTitle_2" class="t-5 t-w-sb t-lh-15 mr-b-20">
        {{ getVacancy.test.testRequirementsTitle_2 }}</p>
      <Ul
        v-if="getVacancy.test.testRequirements_2"
        Size="s"
        :Items="getVacancy.test.testRequirements_2"
      />
    </section>
  </div>
	<!--additional information-->
  <section id="addition">
    <p class="t-5 t-c-pr t-w-sb t-lh-13 t-center mr-b-40">
      Нам очень важно работать в команде, близкой каждому по духу и настроению, а
      также для этой вакансии важна внимательность, поэтому в сопроводительном
      письме укажи коротко:
    </p>
    <div class="wrapper">
      <div class="g-4" v-for="item in addItems" :key="item">
        <p
          class="flex flex-center t-2 t-w-m t-c-g pd-fr-10 br-r-20 bg-white mr-b-20"
        >
          {{ item.num }}
        </p>
        <p class="p-2 t-c-sc t-lh-15 t-center">{{ item.text }}</p>
        <div :class="item.mask" class="mask"></div>
      </div>
    </div>
    <div class="flex flex-center flex-just-center mr-t-40">
      <btn v-if="getVacancy.link" :href="getVacancy.link" size="m" Blank @click="ymGoal">Откликнуться на вакансию</btn>
    </div>
  </section>
</template>

<script>
import SHeroIconList from "@/components/sections/sHeroIconList.vue";
import {mapGetters} from "vuex";

export default {
  name: "Страница Вакансии",
  components: {SHeroIconList},
  data() {
    return {
      addItems: [
        {
          num: "1",
          text: "Почему наша вакансия подходит тебе, и чем твоя кандидатура будет интересна нам.",
          mask: "mask-left",
        },
        {
          num: "2",
          text: "Напиши название продукта, который тебе предстоит поддерживать при найме на эту вакансию",
          mask: "mask-center",
        },
        {
          num: "3",
          text: "Приложи 3 свежих и смешных мема(кидай ссылки прямо в отклик)",
          mask: "mask-right",
        },
      ],
      benefits: [
        {icon: 'icons/work/startup.svg', text: 'Атмосфера настоящего стартапа'},
        {icon: 'icons/work/dms.svg', text: 'ДМС бизнес-уровня (со стоматологией)'},
        {icon: 'icons/work/party.svg', text: 'Интересные корпоративные праздники'},
        {icon: 'icons/work/pop.svg', text: 'Возможность лопать "пупырку" в рабочие дни'},
        {icon: 'icons/work/tech.svg', text: 'Современные технологии в разработке и коммуникации'},
        {icon: 'icons/work/Office.svg', text: 'Лофтовый и уютный офис в Санкт-Петербурге'},
        {icon: 'icons/work/salary.svg', text: 'Оформление по ТК РФ и белая зарплата'},
        {icon: 'icons/work/mortgage.svg', text: 'Льготная ипотека для ИТ специалистов'},
      ],
    };
  },
  computed: {
    ...mapGetters(['getVacancies']),
    getVacancy() {
      let result = {};
      let vm = this;
      this.getVacancies.map(function (category) {
        if (category.value === vm.$route.params.value) {
          category.items.map(function (item) {
            if (item.id === vm.$route.params.id) {
              result = item;
              console.log(result);
            }
          });
        }
      });
      return result;
    },
  },
  methods: {
    ymGoal() {
      window.ym(36084665,'reachGoal','btn_applyjob');
      console.log('goal success');
    }
  }
};
</script>

<style lang="scss" scoped>
#vacancy-hero {
	padding-top: $s-40;
	padding-bottom: $s-40;
}

.flex {
	img {
		width: 100%;
		height: auto;
	}
}

#addition {
	padding-top: $s-40;
	padding-bottom: $s-40;

	.wrapper {
		.g-4 {
			align-items: center;
			padding: $s-40 $s-20;

			.flex {
				width: $s-80;
				@include flex-center;
			}

			.mask {
				display: block;
				position: absolute;
				top: -5%;
				left: -2%;
				width: 104%;
				height: 110%;
				z-index: -1;

				&-left {
					background: url("~@/assets/masks/mask-left.png") no-repeat center/
            100% 100%;
				}

				&-center {
					background: url("~@/assets/masks/mask-center.png") no-repeat center/
            100% 100%;
				}

				&-right {
					background: url("~@/assets/masks/mask-right.png") no-repeat center/
            100% 100%;
				}
			}
		}
	}
}

@media screen and (max-width: $tablet-landscape) {
	#addition {
		.g-4 {
			grid-column-end: span 10;
		}
	}
}

@media screen and (max-width: $tablet-portrait) {
	#vacancy-hero {
		.flex-row:last-child {
			flex-direction: column;

			.mr-r-20 {
				margin-bottom: $s-20;
			}
		}

		.flex-row {
			.p-l {
				margin-bottom: $s-10;
			}
		}
	}
}

@media screen and (max-width: $phone-portrait) {
	#vacancy-hero {
		padding-top: $s-20;
		padding-bottom: $s-20;
	}
	#addition {
		.g-4 {
			margin-bottom: $s-20;

			&:last-child {
				margin-bottom: 0;
			}

			.mask {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
