<template>
  <path d="M2,10l.45.56.12.15L6.5,15.62a1,1,0,0,0,.78.38,1,1,0,0,0,.62-.22,1,1,0,0,0,.16-1.4L5.36,11H17.28a1,1,0,0,0,0-2H5.36l2.7-3.38A1,1,0,1,0,6.5,4.38L2.57,9.29l-.12.15Z" />
</template>

<script>
export default {
    name: "iArrowLeftM",
}
</script>

<style>

</style>