<template>
  <header class="header main_layout">
    <!-- Team Logo Navitaion -->
    <div class="team_nav">
      <router-link class="team_nav_link" to="/"><img src="@/assets/icons/logo/Lab.svg" alt="TP Lab">ТП Лаб</router-link>
      <a class="team_nav_link" href="https://tpkit.ru"><img src="@/assets/icons/logo/Kit.svg" alt="TP Lab">ТП Кит</a>
    </div>
    <!-- Main navigation -->
    <nav class="nav mobile_hidden">
      <router-link class="nav nav-m router-link-active"
      v-for="navs in navItems"
      :key="navs.name"
      :to="navs.link"
      >
      {{navs.name}}
      </router-link>
    </nav>
    <!-- Contact Button -->
    <navBtn @click="$router.push('/contacts')" Size="m" Icon="false" Color="white" href="#" class="btn btn-contact mobile_hidden">Свяжитесь с нами</navBtn>
    <!-- Pop up -->
    <transition name="popup">
      <Popup v-if="$store.state.popupOpen === true">
        <div class="flex">
           <p class="btn btn-green t-5 t-w-sb t-c-pr t-center">Свяжитесь с нами</p>
          <input type="text" class="p-2 pd-fr-10 br-r-12" name="name" placeholder="Имя">
        </div>
      </Popup>
    </transition>
    <!-- Open Hidden Menu -->
    <navBtn Icon="false" class="hidden-menu-open btn btn-contact desktop_hidden" @click="$store.commit('menuOpen')">
        <div class="hidden-line-wrapper" :class="[menuState ? 'hidden-line-wrapper-active' : '']">
          <div class="hidden-menu-line"></div>
          <div class="hidden-menu-line"></div>
          <div class="hidden-menu-line"></div>
        </div>
    </navBtn>
    <!-- Hiddein Menu -->
    <HiddenMenu/>
  </header>
</template>

<script>
import {mapMutations, mapState,} from 'vuex'
import HiddenMenu from '../components/HiddenMenu.vue'
import Popup from '../components/Popup.vue'

export default {
  components: {
    HiddenMenu,
    Popup
  },
computed: {
  ...mapState ({
    navItems: state => state.header.navItems,
    menuState: state => state.header.menuState,
  }),
},
methods: {
  ...mapMutations({
    menuOpen: 'header/menuOpen'
  })
}

}
</script>

<style lang='scss' scoped>
  .header {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    min-height: 4.5rem;
    padding: 1rem 0;
    .team_nav {
      display: flex;
      flex-wrap: nowrap;
      .team_nav_link {
        display: inline-flex;
        align-items: center; 
        font-size: 1.8rem; 
        font-weight: 800; 
        margin-right: 2rem; 
          img {margin-right: 0.5rem;}
      }
    }
    
    //Main Navigation
    .nav {
      margin-left: auto;
      display: flex;
      align-items: center;
      transition: $main-transition;
      color: $secondary;
      cursor:pointer;
      &-s     {font-size: $s-12; margin-right: 3rem; font-weight: 500;}
      &-m     {font-size: 1.5rem; margin-right: 3rem; font-weight: 500;}
      &-l     {font-size: 2rem; margin-right: 3rem; font-weight: 500;}
      &:hover {color: $primary;}
      .router-link-exact-active {
        color: $primary;
      }
    }

    // Contact button
    .hidden-menu-open {
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: $s-20; 
      .hidden-line-wrapper {
        display: block;
        position: relative;
        height: $s-20;
        width: $s-20;
          .hidden-menu-line {
            display: block;
            height: 2px;
            width: 100%;
            background-color: $green;
            position: absolute;
            left: 0;
            @include main-tr;
            &:nth-child(1) {
              top: 1%;
            }
            &:nth-child(2) {
              top: 50%;
            }
            &:nth-child(3) {
              top: 99%;
            }
          }
          &-active {
            .hidden-menu-line {
              &:nth-child(1) {
              transform: rotate(45deg);
              top: 50%;
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:nth-child(3) {
                transform: rotate(-45deg);
                top: 50%;
              }
            }
          }
      }
      
    }
  }
  @media screen and (max-width: $tablet-landscape) {
    .header {
      .mobile_hidden {
        display: none;
        visibility: hidden;
      }
      .desktop_hidden {
        display: flex;
        visibility: visible;
      }
    }
  }
</style>