export const Clients = {
    state: () => ({
        Clients: [
            {link: 'https://megafon.ru', img: 'clients/all/Megafon.svg', index: '1'},
            {link: 'https://mts.ru', img: 'clients/all/MTS.svg', index: '2'},
            {link: 'https://novgorodbirch.com/', img: 'clients/all/NOvgorodBirch.svg', index: '3'},
            {link: 'http://ludilove.ru/', img: 'clients/all/PeopleLove.svg', index: '4'},
            {link: 'https://rd-house.ru/', img: 'clients/all/RoschinHouse.svg', index: '5'},
            {link: 'https://www.dns-shop.ru/', img: 'clients/all/DNS.svg', index: '6'},
            {link: 'https://emg.fm/', img: 'clients/all/EGM.svg', index: '7'},
            {link: 'https://mvideo.ru', img: 'clients/all/M.Video.svg', index: '8'},
            {link: 'http://www.biovat-profil.ru/', img: 'clients/all/Biovat.svg', index: '9'},
            {link: 'https://www.creditasia.uz/', img: 'clients/all/Credit-Asia.svg', index: '10'},
            {link: 'https://www.eldorado.ru/', img: 'clients/all/Eldorado.svg', index: '11'},
            {link: 'https://radiomayak.ru/', img: 'clients/all/Mayak.svg', index: '12'},
            {link: 'https://derevovdom.ru/', img: 'clients/all/RusMaster.svg', index: '13'},
            {link: 'https://sokoldok.ru/', img: 'clients/all/Sokol.svg', index: '14'},
            {link: 'https://www.timproduct.ru/', img: 'clients/all/TimProduct.svg', index: '15'},
          ],
    }),
    getters: {
        getClients(state) {
            return state.Clients
        }
    }
}