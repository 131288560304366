<template>
  <h3 class="t-3 t-c-pr t-w-sb ff-alter mr-b-40 mr-t-40 t-center">Реквизиты</h3>
  <div class="wrapper mr-b-40">
      <div class="g-4 br-r-20 pd-fr-20 bg-white">
          <div class="flex flex-row mr-b-20">
              <img src="@/assets/icons/logo/Lab.svg" alt="TP lab" class="mr-r-10">
              <p class="t-5 t-w-sb">ТП Лаб</p>
          </div>
          <p class="p-2 t-c-sc mr-b-20" title="TpLab">
            ИНН: 7814632410 <br><br>
            КПП: 781401001 <br> <br>
            Счёт(₽): 40702810803270001967 <br> <br>
            <span class="t-w-sb">Банк: ООО "Банк Точка"</span> <br> <br>
            <span class="t-w-sb">БИК: 044525104</span> <br><br>
            Город: Москва <br><br>
            <span class="t-w-sb">Корр. счёт: 30101810745374525104 </span><br><br>

            Генеральный директор: Ивченко Дмитрий Владимирович, действует на основании Устава <br> <br>

            Адрес (юридический и для доставки Почтой России): 197374, Санкт-Петербург г, вн.тер.г. муниципальный
            округ № 65, пр-кт Приморский, д. 163, литера А, помещ. 6Н. <br> <br>

            Адрес (офис и для курьерской доставки): 197374, г. Санкт-Петербург, Приморский пр., 62, корп 1, строение 1, помещение 62-Н
          </p>
      </div>
        <div class="g-4 br-r-20 pd-fr-20 bg-white">
          <div class="flex flex-row mr-b-20">
              <img src="@/assets/icons/logo/Kit.svg" alt="TP lab" class="mr-r-10">
              <p class="t-5 t-w-sb">ТП Кит</p>
          </div>
          <p class="p-2 t-c-sc mr-b-20" title="TpLab">
            ИНН: 7814753260 <br> <br>
            КПП: 781401001 <br> <br>
            Счёт(₽): 40702810703500016418 <br> <br>
            <span class="t-w-sb">Банк: ООО "Банк Точка"</span> <br> <br>
            <span class="t-w-sb">БИК: 044525104</span> <br> <br>
            Город: Москва <br> <br>
            <span class="t-w-sb">Корр. счёт: 30101810745374525104</span> <br> <br>
            Генеральный директор: Ивченко Дмитрий Владимирович, действует на основании Устава<br> <br>
            Адрес (юридический и для доставки Почтой России): 197374, г. Санкт-Петербург, Приморский пр., дом 163, помещение 6-Н. <br> <br>
            Адрес (офис и для курьерской доставки): 197374, г. Санкт-Петербург, Приморский пр., 62, корп 1, строение 1, помещение 62-Н <br> <br>
          </p>
      </div>
  </div>
</template>

<script>
export default {
    name: 'requisites'
}
</script>

<style lang="scss" scoped>
.wrapper {
    .g-4:first-child {
        grid-column-start: 3;
    }
    .flex {
        align-items: center;
    }
}

@media screen and (max-width: $tablet-landscape) {
.wrapper {
  grid-template-columns: repeat(10, 1fr);
  .g-4 {
    grid-column-end: span 4;
    &:first-child {grid-column-start: 2;}
  }
}
}

@media screen and (max-width: $tablet-portrait) {
  br {display: inline;}
.wrapper {
  grid-template-columns: repeat(10, 1fr);
  .g-4 {
    grid-column-end: span 10;
    &:first-child {grid-column-start: 1;}
  }
}
}
</style>
