<template>
  <sHeroFlex :heroImage="'img/prod.png'">
    <template #Title> ТП Лаб – продуктовая команда </template>
    <template #Paragraph>
      История компании ведется с 2000 года как результат объединения нескольких
      проектов разработки программного обеспечения. Объединение позволило
      сочетать компетенции, инфраструктуру и ресурсы, относящиеся к разным
      областям. Кроссдисциплинарность стала основой формирования портфеля
      проектов.
    </template>
    <template #afterParagraph>
      <p class="p-2 t-w-sb mr-t-40 mr-b-20">
        За эти годы результатом нашей работы стали:
      </p>
      <div class="flex flex-row">
        <a href="http://rerotor.ru/" target="_blank" class="flex br-r-12 bg-light-0 pd-fr-20 mr-r-20">
          <img src="@/assets/logos/rerotor.svg" />
        </a>
        <a href="http://pozapisi.ru/" target="_blank" class="flex br-r-12 bg-light-0 pd-fr-20 mr-r-20">
          <img src="@/assets/logos/pozapisi.svg" />
        </a>
        <a href="http://fmmon.ru/" target="_blank" class="flex br-r-12 bg-light-0 pd-fr-20 mr-r-20">
          <img src="@/assets/logos/FmM.svg" />
        </a>
        <a href="http://knotinspector.ru/" target="_blank" class="flex br-r-12 bg-light-0 pd-fr-20 mr-r-20">
          <img src="@/assets/logos/Knot.svg" />
        </a>
        <a href="http://loghouse.pro/" target="_blank" class="flex br-r-12 bg-light-0 pd-fr-20 mr-r-20">
          <img src="@/assets/logos/log-house.svg" />
        </a>
      </div>
    </template>
  </sHeroFlex>
  <sHistory />
  <sValues />
  <sMission />
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.flex {
  .flex {
    justify-content: center;
    align-items: center;
    position: relative;
    height: $s-80;
    width: $s-80;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: $phone-landscape) {
  .flex {
    .flex {
      margin-bottom: $s-20;
    }
  }
}
</style>
