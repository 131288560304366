export const Products = {
    state: () => ({
        products: [
                {
                    id: 'rerotor',
                    name: 'RetailRotor',
                    logo: 'logos/rerotor.svg',
                    illustration: 'illustrations/rerotor_1.svg',
                    heroImage: 'img/products/rerotor-hero.png',
                    version: 'Версия 6.0',
                    area: 'Digital signage',
                    from: '2013 г.',
                    years: '9 лет',
                    description: 'Система видеоценников и видеоэкранов для розничной торговли и сферы услуг, используемая в 1300+ магазинах',
                    link: 'http://rerotor.ru/',
                    contactLink: 'http://rerotor.ru/sales',
                    tips: [
                        {
                            title: 'Функционирует по всей России',
                            text: 'Более 1000 подключенных магазинов в 8 часовых поясах.',
                            icon: 'icons/blocks/box.svg',
                            mask: 'mask-left'
                        },
                        {
                            title: 'Защита экрана от выгорания',
                            text: 'Использование специального режима подвижного интерфейса предотвращает выгорание.',
                            icon: 'icons/blocks/calendar.svg',
                            mask: 'mask-center'
                        },
                        {
                            title: 'Быстрая возможность внедрения',
                            text: 'Всего 1 месяц от знакомства до внедрения',
                            icon: 'icons/blocks/team.svg',
                            mask: 'mask-right'
                        },
                    ]
                },
                {
                    id: 'knot-inspactor',
                    name: 'KnotInspector',
                    logo: 'logos/Knot.svg',
                    illustration: 'illustrations/Knot.svg',
                    heroImage: 'img/products/rerotor-hero.png',
                    version: 'Версия 6.0',
                    area: 'Деревообработка',
                    from: '2014 г.',
                    years: '8 лет',
                    description: 'Сканер пиломатериалов для автоматической сортировки, торцовки и оптимизации раскроя досок',
                    link: 'http://knotinspector.ru',
                    contactLink: 'http://rerotor.ru/sales',
                    tips: [
                        {
                            title: 'Функционирует по всей России',
                            text: 'Более 1000 подключенных магазинов в 8 часовых поясах.',
                            icon: 'icons/blocks/box.svg',
                            mask: 'mask-left'
                        },
                        {
                            title: 'Защита экрана от выгорания',
                            text: 'Использование специального режима подвижного интерфейса предотвращает выгорание.',
                            icon: 'icons/blocks/calendar.svg',
                            mask: 'mask-center'
                        },
                        {
                            title: 'Быстрая возможность внедрения',
                            text: 'Всего 1 месяц от знакомства до внедрения',
                            icon: 'icons/blocks/team.svg',
                            mask: 'mask-right'
                        },
                    ]
                },
                {
                    id: 'fm-monitor',
                    name: 'FM Monitor',
                    logo: 'logos/FmM.svg',
                    illustration: 'illustrations/FmM.svg',
                    heroImage: 'img/products/rerotor-hero.png',
                    version: 'Версия 6.0',
                    area: 'Радио вещание',
                    from: '2010 г.',
                    years: '12 лет',
                    description: 'Система мониторинга радиоэфира для федеральных медиахолдингов',
                    link: 'http://fmmon.ru/',
                    contactLink: 'http://rerotor.ru/sales',
                    tips: [
                        {
                            title: 'Функционирует по всей России',
                            text: 'Более 1000 подключенных магазинов в 8 часовых поясах.',
                            icon: 'icons/blocks/box.svg',
                            mask: 'mask-left'
                        },
                        {
                            title: 'Защита экрана от выгорания',
                            text: 'Использование специального режима подвижного интерфейса предотвращает выгорание.',
                            icon: 'icons/blocks/calendar.svg',
                            mask: 'mask-center'
                        },
                        {
                            title: 'Быстрая возможность внедрения',
                            text: 'Всего 1 месяц от знакомства до внедрения',
                            icon: 'icons/blocks/team.svg',
                            mask: 'mask-right'
                        },
                    ]
                },
                {
                    id: 'log-house',
                    name: 'LogHouse',
                    logo: 'logos/log-house.svg',
                    illustration: 'illustrations/LogHouse.svg',
                    heroImage: 'img/products/rerotor-hero.png',
                    version: 'Версия 6.0',
                    area: 'Радио вещание',
                    from: '1999 г.',
                    years: '23 года',
                    description: 'Система проектирования и оптимального производства срубов из оцилиндрованного бревна и бруса',
                    link: 'http://loghouse.pro',
                    contactLink: 'http://rerotor.ru/sales',
                    tips: [
                        {
                            title: 'Функционирует по всей России',
                            text: 'Более 1000 подключенных магазинов в 8 часовых поясах.',
                            icon: 'icons/blocks/box.svg',
                            mask: 'mask-left'
                        },
                        {
                            title: 'Защита экрана от выгорания',
                            text: 'Использование специального режима подвижного интерфейса предотвращает выгорание.',
                            icon: 'icons/blocks/calendar.svg',
                            mask: 'mask-center'
                        },
                        {
                            title: 'Быстрая возможность внедрения',
                            text: 'Всего 1 месяц от знакомства до внедрения',
                            icon: 'icons/blocks/team.svg',
                            mask: 'mask-right'
                        },
                    ]
                },
                {
                    id: 'po-zapisi',
                    name: 'ПоЗаписи',
                    logo: 'logos/pozapisi.svg',
                    illustration: 'illustrations/Pozapisi.svg',
                    heroImage: 'img/products/pozapisi.png',
                    version: 'Версия 6.0',
                    area: 'Радио вещание',
                    from: '2009 г.',
                    years: '13 лет',
                    description: 'Бронирование залов и услуг для творческих людей',
                    link: 'http://pozapisi.ru',
                    contactLink: 'http://rerotor.ru/sales',
                    tips: [
                        {
                            title: 'Функционирует по всей России',
                            text: 'Более 1000 подключенных магазинов в 8 часовых поясах.',
                            icon: 'icons/blocks/box.svg',
                            mask: 'mask-left'
                        },
                        {
                            title: 'Защита экрана от выгорания',
                            text: 'Использование специального режима подвижного интерфейса предотвращает выгорание.',
                            icon: 'icons/blocks/calendar.svg',
                            mask: 'mask-center'
                        },
                        {
                            title: 'Быстрая возможность внедрения',
                            text: 'Всего 1 месяц от знакомства до внедрения',
                            icon: 'icons/blocks/team.svg',
                            mask: 'mask-right'
                        },
                    ]
                },
            ]
    }),
    getters: {
        getProducts(state) {
            return state.products
        }
    },
    mutations: {

    },
    actions: {

    }
}