<template>
  <section id="clients">
    <div class="t-3 t-w-sb ff-alter t-lh-1 t-center mr-b-20">Наши клиенты</div>
    <div :class="`glide-${galleryName}`" class="glide" id="#glide-clients" ref="cliGlide">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <keep-alive>
          <li class="glide__slide" v-for="item in $store.getters.getClients" :key="item">
            <a :href="item.link" target="_blank" class="img-wrapper">
              <img :src="require(`@/assets/${item.img}`)" :alt="item.index"/>
            </a>
          </li>
          </keep-alive>
        </ul>
      </div>
      <div class="glide__bullets" data-glide-el="controls[nav]">
        <button v-for="nav in $store.getters.getClients" :key="nav" class="glide__bullet" :data-glide-dir="`=${nav.index}`"></button>
      </div>
    </div>
  </section>
</template>

<script>

import Glide from "@glidejs/glide";

export default { 
  name: "sClients",
  data() {
    return {
      galleryName: 'cli',
      Type: 'carousel',
      itemsToShow: 6,
      gaps: 0,
      autoPlay: 3000,
      perTouch: 6,
      breakPoints: {
        980: {
          perView: 3,
          perTouch: 6,
        },
        480: {
          perView: 2,
          perTouch: 3,
        }
      }
    }
  },
  mounted() {
    new Glide(`.glide-${this.galleryName}`, {
      type: this.Type,
      perView: this.itemsToShow,
      gap: this.gaps,
      autoplay: this.autoPlay,
      breakpoints: this.breakPoints,
      perTouch: 3,
    }).mount();
  }
};
</script>

<style lang="scss" scoped>
  @import "@/styles/glidejs.scss";
#clients {
  padding-top: $s-40;
  padding-bottom: $s-40;
  .glide {
    position: relative;
    .glide__track {
      .glide__slides {
        .glide__slide {
          display: flex;
          @include flex-center;
          min-height: $s-100;
          img {
            @include main-tr;
            -webkit-filter: grayscale(100%);
            &:hover {
              -webkit-filter: grayscale(0%);
            }
          }
        }
      }
    }
    .glide__bullets {
      position: relative;
      display: none;
      @include flex-center;
      position: relative;
      margin-top: $s-40;
      .glide__bullet {
        display: inline-block;
        background-color: $light;
        height: $s-10;
        width: $s-10;
        margin-right: $s-20;
        border-radius: $s-20;
        box-shadow: none;
        border: none;
        &:hover {
          background-color: $secondary;
        }
      }
      .glide__bullet--active {
        background-color: $green;
        border: none;
      }
    }
  }
}
@media screen and (max-width: $tablet-landscape-min) {
  #clients {
    .glide{
      .glide__bullets {
        display: flex;
      }
    }
  }
}
</style>
