<template>
  <div class="tabs flex flex-row" :class="[`tabs-${Size}`, {'tabs-center' : Center}]" >
      <div 
      v-for="item in Items"
      :key="item.value"
      :class="[`tab-${Size}`, {'tab-active' : active === item.value}]" 
      class="tab flex"
      @click="selectItem(item)"
      >
      {{item.name}}</div>
  </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        Size: {
            type: String,
            default: 'm'
        },
        Items: {
            type: Array,
        },
        Center: {
            type: Boolean,
            default: false
        },
        active: {
            type: String
        }
    },
    data() {
        return {
            activeTab: this.active
        }
    },
    methods: {
        selectItem(item) {
            this.activeTab = item.value;
            this.$emit('currentTab', this.activeTab);
        }
    },
}
</script>

<style lang="scss" scoped>
    .tabs {
        width: fit-content;
        overflow: hidden;
        &-s {border-radius: $s-8;}
        &-m {border-radius: $s-10;}
        &-l {border-radius: $s-12;}
        &-center {@include margin-auto;}
        .tab {
            color: $secondary;
            font-weight: 500;
            background-color: $white;
            border-right: 1px solid $light;
            @include flex-center;
            @include main-tr;
            flex-grow: 1;
            flex: 1;
            cursor: pointer;
            &:last-child {border: none;}
            &-s {padding: $s-10; font-size: $s-15; line-height: 1.3; min-width: $s-120;}
            &-m {padding: $s-15; font-size: $s-20; line-height: 1; min-width: $s-90*2;}
            &-l {padding: $s-20; font-size: $s-20; line-height: 1; min-width: $s-100*2;}
            &-active {background-color: $green; color: $white; font-weight: 700;}
        }
    }
    @media screen and (max-width: $phone-portrait) {
        .tabs {
            .tab {
                &-s {min-width: fit-content;}
                &-m {min-width: fit-content;}
                &-l {min-width: fit-content;}
            }
        }
    }
</style>