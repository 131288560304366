<template>
  <rect width="15" height="12.5" x="2.5" y="3.75" class="cls-1" rx="2" />
  <path d="M17.5,7.5l-6.65,4.43a1.51,1.51,0,0,1-1.7,0L2.5,7.5" class="cls-1" />
</template>

<script>
export default {
  name: 'iMail'
};
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke: $white;
    stroke-miterlimit: 10;
  }
</style>
