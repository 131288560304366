import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import TpKitPage from '../views/TpKitPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import WorkPage from '../views/WorkPage.vue'
import CooperationPage from '../views/CooperationPage.vue'
import ContactsPage from '../views/ContactsPage.vue'
import ProductsPage from '../views/ProductsPage.vue'
import RequisitesPage from '../views/RequisitesPage.vue'
import VacancyPage from '../views/VacancyPage.vue'
import ProductPage from '../views/ProductPage.vue'
import IntellectualProperty from '../views/IntellectualProperty.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import mainLayout from '../layouts/mainLayout'


const routes = [
  
  {
    path: '/',
    name: 'ТП Лаб',
    component: HomePage,
    meta: {
      layout: mainLayout,
      title: 'ТП Лаб',
      keywords: ''
    },
  },
  {
    path: '/about',
    name: 'О компании',
    component: AboutPage,
    meta: {
      layout: mainLayout,
      title: 'О компании',
      keywords: ''
    },
  },
  {
    path: '/tp_kit',
    name: 'ТП Кит',
    component: TpKitPage,
    meta: {
      layout: mainLayout,
      title: 'ТП Кит',
      keywords: ''
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Страница не найдена',
    component: NotFoundPage,
    meta: {
      layout: mainLayout,
      title: 'Страница не найдена',
    }
  },
  {
    path: '/career',
    name: 'Работа в компании',
    component: WorkPage,
    meta: {
      layout: mainLayout,
      title: 'Работа в компании',
      keywords: ''
    }
  },
  {
    path: '/career/:value/:id',
      name: 'Страница вакансии',
      component: VacancyPage,
      meta: {
        layout: mainLayout,
        title: 'Вакансия',
        keywords: ''
      }
  },
  {
    path: '/cooperation',
    name: 'Сотрудничество',
    component: CooperationPage,
    meta: {
      layout: mainLayout,
      title: 'Сотрудничество',
      keywords: ''
    }
  },
  {
    path: '/contacts',
    name: 'Контакты',
    component: ContactsPage,
    meta: {
      layout: mainLayout,
      title: 'Контакты',
      keywords: ''
    }
  },
  {
    path: '/products',
    name: 'Продукты',
    component: ProductsPage,
    meta: {
      layout: mainLayout,
      title: 'Продукты',
      keywords: ''
    }
  },
  {
    path: '/products/:id',
    name: 'Продукт',
    component: ProductPage,
    meta: {
      layout: mainLayout,
      title: 'Продукт',
      keywords: ''
    }
  },
  {
    path: '/req',
    name: 'Реквизиты',
    component: RequisitesPage,
    meta: {
      layout: mainLayout,
      title: 'Реквизиты',
      keywords: ''
    }
  },
  {
    path: '/intellectual-property',
    name: 'Интеллектуальная собственность компании',
    component: IntellectualProperty,
    meta: {
      layout: mainLayout,
      title: 'Интеллектуальная собственность компании',
      keywords: ''
    }
  },
  {
    path: '/privacy-policy',
    name: 'Политика конфиденциальнсоти',
    component: PrivacyPolicy,
    meta: {
      layout: mainLayout,
      title: 'Политика конфиденциальнсоти'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {top: 0}
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})


export default router
