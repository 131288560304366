export const Vacansies = {
  state: () => ({
    jobs: [
      {
        name: 'Вакансии', value: 'vacancy', items: [
          {
            id: 'technical-support-2024',
            link: 'https://t.me/lizatplab',
            name: 'Специалист технической поддержки',
            jobType: 'Тех.поддержка',
            steck: [],
            about: 'Мы расширяем команду внедрений и технической поддержки. Мы готовы учить перспективного специалиста, даже если у него нет профильного опыта, но есть нужные навыки, а также предлагаем симпатичные и конкурентные условия работы. Ищем коллегу, который будет долго и плодотворно совместно с нами развиваться. С 2013 года наша компания развивает собственный продукт в Digital Signage & Labeling - Retail Rotor, работающий по всей России: от Южно-Сахалинска до Калининграда и в ближнем зарубежье.',
            jobRequirements: [
              'Умение грамотно писать и говорить, вести деловую переписку',
              'Аккуратность и ответственность, внимательность и усидчивость',
              'Знание систем Service Desk',
              'Желание расти и учиться вместе с командой',
              'Умение работать самостоятельно и в команде (мы очень ценим ответственность и обязательность)',
              'Базовые знания Linux и Docker',
              'Готовность собирать оригами из коробок и любовь к тетрису (складскому)'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Работать на 1-й/2-й линии техподдержки (чаты и почта) самой крупной в России системы видеоценников (B2B - сотрудники наших Заказчиков)',
                'Первично настраивать оборудование для Заказчиков',
                'Принимать оборудование от поставщиков',
                'Комплектовать оборудование для поставок Заказчикам',
              ]
            }
          },
          {
            id: 'circuit-2023',
            link: 'https://t.me/lizatplab',
            name: 'Программист-схемотехник',
            jobType: 'Схемотехника',
            steck: ['STM32', 'Git', 'ECAD', 'ПЛК'],
            about: 'В нашу лабораторию разработки программно-аппаратного комплекса KnotInspector мы ищем коллегу на вакансию программиста-схемотехника. Мы готовы учить перспективного специалиста, даже если у тебя нет профильного опыта, но есть нужные навыки. Взамен, мы за талант предлагаем симпатичные и конкурентные условия работы.',
            jobRequirements: [
              'Имеешь техническое образование или дополнительное профильное образование',
              'Владеешь программированием STM32',
              'Читаешь и разрабатывашь схемотехническую документацию, можешь проектировать печатные платы',
              'Собираешь радиоэлектронную аппаратуру',
              'Владеешь монтажом РЭА',
              'Понимаешь, зачем нужен git и ветки в нём',
              'Умеешь работать самостоятельно (мы ценим надежность и честность)',
              'Внимателен к мелочам и любишь доходить до самой сути',
              'Стремишься развиваться'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Участвовать в полном цикле разработки и внедрения программно-аппаратного комплекса сканера пиломатериалов',
                'Программировать микроконтроллеры',
                'Проводить код ревью',
                'Сопровождать решения от разработки до установки у заказчика',
                'Создавать конструкторскую документацию для производства печатных плат',
                'Взаимодействовать со специалистами команды качества, команды внедрений и технической поддержки',
                'Участвовать в выездных работах на объектах заказчика'
              ],
            },
          }
        ]
      },
      {
        name: 'Стажировки', value: 'interships', items: [
          {
            archive: false,
            link: 'mailto:hr@truepositive.ru',
            id: 'data-marker-for-wood-ai',
            name: 'Разметчик данных для деревообработки',
            jobType: 'Попроектная работа',
            steck: ['Без опыта', 'Умение работать удаленно'],
            about: 'В нашу ламповую лабораторию мы ищем коллегу на вакансию разметчик данных. Твой задачей будет обучение нейросети, определяющей пороки древесины. ',
            jobRequirements: [
              'Разбираешься в пороках древесины - можешь отличить синеву от гнили и мертвый сучок от живого.',
              'Внимателен и аккуратен.',
              'Умеешь работать самостоятельно и выполняешь задачи в срок.'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Размечать фотографии досок из сосны, елки, березы и дуба используя инструмент разметки COCO Annotator.',
                'Работа с пакетами по 100-500 фотографий.'
              ]
            }
          }
        ]
      },
      {
        name: 'Архив', value: 'archive', items: [
          {
            id: 'technical support-2023',
            link: 'https://t.me/lizatplab',
            name: 'Специалист технической поддержки',
            jobType: 'Тех.поддержка',
            steck: [],
            about: 'Мы расширяем команду внедрений и технической поддержки. Мы готовы учить перспективного специалиста, даже если у него нет профильного опыта, но есть нужные навыки, а также предлагаем симпатичные и конкурентные условия работы. Ищем коллегу, который будет долго и плодотворно совместно с нами развиваться. С 2013 года наша компания развивает собственный продукт в Digital Signage & Labeling - Retail Rotor, работающий по всей России: от Южно-Сахалинска до Калининграда и в ближнем зарубежье.',
            jobRequirements: [
              'Умение грамотно писать и говорить, вести деловую переписку',
              'Аккуратность и ответственность, внимательность и усидчивость',
              'Знание систем Service Desk',
              'Желание расти и учиться вместе с командой',
              'Умение работать самостоятельно и в команде (мы очень ценим ответственность и обязательность)',
              'Базовые знания Linux и Docker',
              'Готовность собирать оригами из коробок и любовь к тетрису (складскому)'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Работать на 1-й/2-й линии техподдержки (чаты и почта) самой крупной в России системы видеоценников (B2B - сотрудники наших Заказчиков)',
                'Первично настраивать оборудование для Заказчиков',
                'Принимать оборудование от поставщиков',
                'Комплектовать оборудование для поставок Заказчикам',
              ]
            }
          },
          {
            id: 'junior-backend-2024',
            link: 'https://t.me/lizatplab',
            name: 'Backend разработчик Python (junior+)',
            jobType: 'Backend',
            steck: ['Python', 'Django Framework', 'Git', 'PostgreSQL', 'Docker', 'Английский — A1 — Начальный'],
            about: 'В нашу лабораторию разработки программно-аппаратного комплекса KnotInspector и самого крутого сервиса Digital Signage & Labeling мы ищем коллегу на вакансию программиста.',
            jobRequirements: [
              'Имеешь техническое образование или дополнительное профильное образования',
              'Владеешь Python 3.8 (и выше), имеешь опыт работы с Django, Django Rest Framework, Celery',
              'Разбираешься в PostgreSQL и MongoDB',
              'Работаешь с Docker',
              'Знаешь, чем поток отличается от процесса',
              'Понимаешь, зачем нужен git и ветки в нём',
              'Умеешь работать самостоятельно (мы ценим надежность и честность)',
              'Внимателен к мелочам и любишь доходить до самой сути',
              'Стремишься развиваться'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему будущему коллеге предстоит:',
              testRequirements: [
                'Участвовать в полном цикле разработки и внедрения программно-аппаратного комплекса сканера пиломатериалов.',
                'Поддерживать и развивать серверную часть самого крутого сервиса Digital Signage & Labeling в России (более 70 000 экранов, работа в 8 часовых поясах).',
                'Сопровождать решения от разработки до установки у заказчика, обеспечивать 3-ю линию технической поддержки.',
                'Проводить код ревью.',
                'Взаимодействовать со специалистами команды качества, команды внедрений и технической поддержки.'
              ]
            }

          },
          {
            id: 'middle-backend-2022',
            name: 'Middle backend разработчик',
            jobType: 'Python',
            steck: ['Python 3.8 (и выше)', 'Django', 'Django Rest Framework', 'Celery', 'PostgreSQL', 'MongoDB', 'Docker'],
            about: 'В нашу ламповую команду мы ищем коллегу на вакансию опытного backend разработчика.',
            jobRequirements: [
              'Имеешь техническое образование или дополнительное профильное образование',
              'Владеешь Python 3.8 (и выше), имеешь опыт работы с Django, Django Rest Framework, Celery',
              'Разбираешься в PostgreSQL и MongoDB',
              'Работаешь с Docker',
              'Знаешь, чем поток отличается от процесса',
              'Понимаешь, зачем нужен git и ветки в нём',
              'Умеешь работать самостоятельно (мы ценим надежность и честность)',
              'Внимателен к мелочам и любишь доходить до самой сути',
              'Стремишься развиваться'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Поддерживать и развивать серверную часть самого крутого сервиса Digital Signage & Labeling в России (более 70 000 экранов, работа в 8 часовых поясах, http://rerotor.ru)',
                'Участвовать в разработке архитектуры сервиса',
                'Проводить код ревью',
                'Сопровождать решения от разработки до деплоя на сервер',
                'Взаимодействовать со специалистами команды качества, команды внедрений и технической поддержки',
                'Обеспечивать техническую поддержку 3-й линии'
              ],
            },
          },
          {
            id: 'senior-python-dev-2022',
            name: 'Senior разработчик',
            jobType: 'Python',
            steck: ['Python 3.8 (и выше)', 'Django Framework', 'PostgreSQL', 'Django Rest Framework', 'Celery', 'MongoDB', 'Docker', 'Английский язык'],
            about: 'В нашу команду мы ищем коллегу на вакансию руководителя группы разработки (senior разработчика/Архитектора) - продуктовоориентированного специалиста, который хочет/может быть руководителем.',
            jobRequirements: [
              'Хотите развивать продукт, вам нравится работать с заказчиками по техническим вопросам встраивания бизнес-логики в архитектуру продукта',
              'Имеете опыт работы архитектром или лидом для команды разработки, знаете паттерны проектирования и умеете их применять в реальных проектах',
              'Имеете высшее (техническое) или дополнительное профильное техническое образование',
              'Владеете Python 3.8 (и выше), имеете опыт работы с Django, Django Rest Framework, Celery, разбираетесь в PostgreSQL и MongoDB, используете Docker',
              'Стремитесь развиваться и не боитесь мечтать'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Руководить группой разработчиков продукта: распределять задачи, контролировать их исполнение, выступать в роли наставника для джунов (backend, frontend) крутого сервиса Digital Signage & Labeling в России (более 70 000 экранов, работа в 8 часовых поясах)',
                'Выступать системным аналитиком и готовить тех.задания для исполнителей в команде разработки продукта',
                'Взаимодействовать с заказчиками по вопросам заказных разработок и техническим директором по вопросам продуктовых фич',
                'Участвовать в планировании спринтов',
                'Согласовывать бизнес-логику с разрабатываемой архитектурой: приоритезировать задачи рефакторинга с учетом планов заказных разработок и развития продуктовых фич',
                'Взаимодействовать с командой качества, командой внедрений и технической поддержки, организовывать техническую поддержку 3-й линии',
                'Программировать (как сеньор-помидор ;))'
              ]
            }
          },
          {
            id: 'tech-support-2022',
            archive: true,
            name: 'Специалист технической поддержки (джун/джун+)',
            jobType: 'Техническая поддержка',
            steck: ['Service Desk', 'Linux и Docker'],
            about: 'Мы расширяем команду внедрений и технической поддержки. Мы готовы учить перспективного специалиста, даже если у него нет профильного опыта, но есть нужные навыки (см. ниже), а также предлагаем симпатичные и конкурентные условия работы. Ищем коллегу, который будет долго и плодотворно совместно с нами развиваться.',
            jobRequirements: [
              'Умение грамотно писать и говорить, вести деловую переписку.',
              'Аккуратность и ответственность, внимательность и усидчивость.',
              'Знание систем Service Desk.',
              'Желание расти и учиться вместе с командой.',
              'Умение работать самостоятельно и в команде (мы очень ценим ответственность и обязательность),',
              'Базовые знания Linux и Docker',
              'Готовность собирать оригами из коробок и любовь к тетрису (складскому).'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему будущему коллеге предстоит:',
              testRequirements: [
                'Работать на 1-й/2-й линии техподдержки (чаты и почта) самой крупной в России системы видеоценников (B2B - сотрудники наших Заказчиков).',
                'Первично настраивать оборудование для Заказчиков.',
                'Принимать оборудование от поставщиков.',
                'Комплектовать оборудование для поставок Заказчикам.'
              ]
            }
          },
          {
            id: 'junior-backend-2022',
            archive: true,
            name: 'Backend разработчик Python (junior+ / middle)',
            jobType: 'Python',
            steck: ['Python 3.8 (и выше)', 'Django', 'Django Rest Framework', 'Celery', 'PostgreSQL', 'MongoDB', 'Docker'],
            about: 'В нашу ламповую команду мы ищем коллегу на вакансию backend разработчика на продукт Retail Rotor',
            jobRequirements: [
              'Имеешь техническое образование или дополнительное профильное образование',
              'Владеешь Python 3.8 (и выше), имеешь опыт работы с Django, Django Rest Framework, Celery',
              'Разбираешься в PostgreSQL и MongoDB',
              'Работаешь с Docker',
              'Знаешь, чем поток отличается от процесса',
              'Понимаешь, зачем нужен git и ветки в нём',
              'Умеешь работать самостоятельно (мы ценим надежность и честность)',
              'Внимателен к мелочам и любишь доходить до самой сути',
              'Стремишься развиваться'
            ],
            test: {
              testName: 'В часы трудовых подвигов нашему коллеге предстоит:',
              testRequirements: [
                'Поддерживать и развивать серверную часть самого крутого сервиса Digital Signage & Labeling в России (более 70 000 экранов, работа в 8 часовых поясах, http://rerotor.ru)',
                'Проводить код ревью',
                'Сопровождать решения от разработки до деплоя на сервер',
                'Взаимодействовать со специалистами команды качества, команды внедрений и технической поддержки',
                'Обеспечивать техническую поддержку 3-й линии'
              ],
            },

          },
          {
            id: 'backend_2021',
            archive: true,
            name: 'Backend-разработчик',
            jobType: 'Backend-developer',
            steck: ['PostgreSQL', 'MongoDB', 'Docker', 'Django', 'Django Rest Framework', 'Celery', 'Python'],
            about: 'В нашу команду мы ищем коллегу на вакансию backend-разработчика с опытом работы от двух лет.',
            jobRequirements: [
              'Имеете высшее/незаконченное высшее техническое образование',
              'Уверенно владеете Python 3.8 (и выше), имеете опыт работы с Django, Django Rest Framework, Celery',
              'Понимаете, зачем нужен git и ветки в нём',
              'Разбираетесь в PostgreSQL и MongoDB',
              'Знакомы с Docker',
              'Знаете, чем поток отличается от процесса',
              'Умеете работать самостоятельно и в команде (мы очень ценим ответственность и обязательность)',
              'Внимательны к мелочам и любите доходить до самой сути',
              'Стремитесь развиваться и не боитесь мечтать'
            ],
            testHere: 'true',
            test: {
              testName: 'Тестовое задание "Разработать простой укорачиватель ссылок"',
              testType: 'Разработать простой укорачиватель ссылок.',
              testDescription: 'Укорачиватель получает адрес некоторой веб-страницы, укорачивает все ссылки на ней и заменяет обычные на укороченные.',
              testRequirementsTitle: 'Требования к заданию',
              testRequirements: [
                'Реализовать на языке Python',
                'Должна быть возможность настроить параметры работы укорачивателя - через переменные окружения или конфигурационный файл',
                'Укороченная ссылка должна открываться в браузере, инкрементировать счетчик и возвращать редирект на по исходной ссылке',
              ],
            },
          },
          {
            id: 'support',
            archive: true,
            name: 'Специалист технической поддержки',
            jobType: 'Младший специалист',
            // steck: ['PostgreSQL', 'MongoDB', 'Docker', 'Django', 'Django Rest Framework', 'Celery', 'Python'],
            about: 'Мы ищем коллегу на техническую поддержку в СПб.',
            jobRequirements: [
              'Работа в режиме онлайн на первой линии техподдержки самой крупной в России системы видеоценников (по фиксированному графику 5/2)',
              'Первичная настройка оборудования.',
              'Комплектация оборудования для поставок Заказчикам',
              'Прием оборудования от поставщиков, учет и участие в инвентаризации',
              'Участие в проектах внедрения/развертывания решения на объектах Заказчика.',
              'Сбор обратной связи по направлению работы и взаимодействие с командами разработки и тестирования',
            ],
            testHere: 'true',
            test: {
              testName: 'Тестовое задание',
              testType: 'Переноса директории',
              // testDescription: 'На ПК с ОС Ubuntu имеется один диск, все директории расположены на первом разделе диска /dev/sda1 и смонтированы в корневой каталог "/". Был подключен новый диск. Опишите Ваши действия для переноса директории /home целиком на новый диск. Есть 5 ПК, подключенных к роутеру (Интернету) через свитч. Один из пользователей ПК сообщил об отсутствии доступа в сеть Интернет. Опишите порядок действий для диагностики проблемы.',
              testRequirementsTitle: 'Требования к заданию',
              testRequirements: [
                'На ПК с ОС Ubuntu имеется один диск, все директории расположены на первом разделе диска /dev/sda1 и смонтированы в корневой каталог "/".Был подключен новый диск. Опишите Ваши действия для переноса директории /home целиком на новый диск',
                'Есть 5 ПК, подключенных к роутеру (Интернету) через свитч. Один из пользователей ПК сообщил об отсутствии доступа в сеть Интернет. Опишите порядок действий для диагностики проблемы',
                'Как изменить порт по-умолчанию для SSH на сервере Linux?',
                'У вас появилась необходимость посмотреть лог какого-либо приложения в Linux. Какие инструменты будете использовать для этого? Может быть их будет несколько, в зависимости от ситуации? Обоснуйте свой выбор.',
                'Как в Linux создать файл заданного размера, например 100Мб?',
                'Напишите небольшую инструкцию по использованию вашего любимого приложения на смартфоне',
                'Сотрудник, работающий удаленно из дома просит Вас с вашего рабочего места в офисе подключиться по RDP к его домашнему ПК для настройки ПО и присылает вывод команды ipconfig со своего компьютера: DNS-суффикс подключения: IPv4-адрес: 172.16.34.15 . Маска подсети : 255.255.255.0 . Основной шлюз : 172.16.34.1. Вы пытаетесь подключиться по указанному адресу, но безуспешно. Почему? Какие действия необходимо произвести для подключения к ПК сотрудника?',
                'Напишите ответное письмо недовольному клиенту (текст письма ниже): Здравствуйте!Сегодня я обратился с проблемой подключения к Вашим ресурсам, 40 минут ждал ответа, наконец сотрудник Вашей компании Георгий взял мое обращение в работу, заверив, что обязательно свяжется со мной чуть позже, и пропал. Через 2 часа я снова позвонил, но оказалось, что Георгий даже не зарегистрировал мое обращение и, соотвественно, никто из сотрудников Вашей компании не знает, что мне нужно было перезвонить. Прошу оформить жалобу.'
              ],
            },
          },
          {
            id: 'quality_engineer',
            archive: true,
            name: 'Инженер по качеству',
            jobType: 'Младший специалист',
            steck: ['Ручное тестирование', 'Автоматическое тестирование', 'YouTrack', 'Windows', 'macOS', 'Linux', 'Android'],
            about: 'В нашу команду мы ищем коллегу на вакансию инженера по качеству',
            jobRequirements: [
              'Ручное и автоматическое тестирование сайтов и приложений компании на множестве платформ и устройств (Windows, macOS, Linux, Android)',
              'Ведение тестовой документации: чек-листы, тест-кейсы, тест-планы, баг-репорты',
              'Локализация и документирование дефектов в YouTrack',
              'Тесное взаимодействие с разработчиками, технической поддержкой и пользователями',
            ],
            testHere: 'true',
            test: {
              testName: 'Тестовое задание',
              testType: 'Cоставить тест-план',
              testDescription: 'Поступило ТЗ от менеджера проекта: «Необходимо предоставить возможность загрузки видео роликов на русскоязычный сайт с указанием угла поворота» Сможете по данному заданию составить тест-план? Если нет, какую информацию надо запросить?',
              testImg: '',
              testRequirementsTitle: '',
              testRequirements: [],
              testName_2: 'Тестовое задание 2',
              testType_2: '',
              testDescription_2: 'Разместить на сайте на отдельной странице (Импорт вобблеров) форму для загрузки акций. Данные акции будут выводиться на устройства в зависимости от идентификатора акции. Идентификатор уникален. Предусмотреть возможность просмотра акции и удаления. Составьте чек-лист на проверку, 5 тест-кейсов (можно больше) , оформите баги, если они есть.',
              testImg_2: 'vacancies/123.png',
              testRequirementsTitle_2: 'Форма загрузки состоит:',
              testRequirements_2: [
                'Указание загружаемого файла. Для загрузки доступны только png файлы',
                'Имя. Если имя не заполнено, то использовать имя файла. Если заполнить имя, то при загрузке отображать его.',
                'Категория. Выпадающий список, состоящий из «Акции» и «Промо»',
                'Кнопка для загрузки'
              ]
            },
          },
        ]
      }
    ],
    tabActive: "vacancy"
  }),
  mutations: {},
  getters: {
    getVacancies(state) {
      return state.jobs
    },
    getTab(state) {
      return state.tabActive
    }
  },
  actions: {}
}
