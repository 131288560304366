<template>
  <p>This is 404 page!</p> 
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>