<template>
  <sHero
  Size="t-2"
  >
    <template #Title>
      ТП Лаб - лаборатория IV <br> промышленной революции
    </template>
    <template #Subtitle>
        За 20 лет существования компании мы реализовали наш опыт в продуктах для разных сфер. <br> Задачи бизнеса бывают разные, и мы стараемся отвечать на каждый вызов и разработать эффективное<br>решение. На пике современных технологий и с любовью к разработке.
    </template>
    <div class="link link-1 bg-light br-r-20 pd-fr-20">
      <img src="~@/assets/logos/rerotor.svg">
    </div>
    <div class="link link-2">
      <img src="~@/assets/illustrations/Cooperation/2.svg">
    </div>
  </sHero>
  <sProducts :bottonOn="false"/>
  <sTechnologies/>
  <sClients :Items="$store.getters.getClients"/>
  <sPartners :Items="$store.getters.getPartners"/>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.flex {
  max-width: 50rem;
}
.link {
  display: flex;
  @include flex-center;
  position: absolute;
  z-index: -1;
  &-1 {top: 10%; left: 10%;}
  &-2 {bottom: 15%; right: 0%;}
}
@media screen and (max-width: $tablet-landscape) {
  .link {
    &-1 {left: 5%;}
    &-2 {bottom: 10%;}
  }
}
@media screen and (max-width: $tablet-landscape-min) {
  .link {
    &-1 {left: 0%; top: 5%;}
    &-2 {bottom: 5%; right: -5%;}
  }
}
@media screen and (max-width: $tablet-portrait) {
  .link {
    &-1 {top: 0%;}
  }
}
@media screen and (max-width: $phone-landscape) {
  .link {
    &-1 {top: 5%; left: -5%;}
    &-2 {bottom: 10%; right: -10%;}
  }
}
@media screen and (max-width: $phone-portrait) {
  .link {
    transform: scale(0.9);
    &-1 {top: 0%; left: -2%;}
    &-2 {bottom: -5%; right: -10%;}
  }
}
</style>