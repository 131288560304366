<template>
  <ul class="list flex flex-row flex-between">
      <li class="list-item" :class="[`list-item-${Size}`, `list-item-${Color}`]" v-for="item in Items" :key="item">{{item}}</li>
  </ul>
</template>

<script>
export default {
    name: 'Ul',
    props: {
        Size: {
            type: String,
            default: 's'
        },
        Color: {
            type: String,
            default: 'main'
        },
        Items: {
            type: Array
        }
    }
}
</script>

<style lang="scss" scoped>
    .list {
        .list-item {
            display: flex;
            width: calc(100% / 2 - $s-40);
            height: fit-content;
            &-s{
                font-size: $s-15;
                line-height: 1.5;
                margin-bottom: $s-15;
                &::before {
                content: '';
                display: inline-block;
                height: $s-10;
                width: $s-10;
                min-height: $s-10;
                min-width: $s-10;
                margin-top: $s-6;
                margin-right: $s-10;
                border-radius: $s-20;
                }
            }
            &-m {
                font-size: $s-20;
                line-height: 1.5;
                margin-bottom: $s-10;
                &::before {
                content: '';
                display: inline-block;
                height: $s-15;
                width: $s-15;
                min-height: $s-15;
                min-width: $s-15;
                margin-top: $s-8;
                margin-right: $s-10;
                border-radius: $s-20;
                }
            }
            &-l {
                font-size: $s-20;
                line-height: 1.5;
                margin-bottom: $s-15;
                &::before {
                content: '';
                display: inline-block;
                height: $s-20;
                width: $s-20;
                min-height: $s-20;
                min-width: $s-20;
                margin-top: $s-4;
                margin-right: $s-15;
                border-radius: $s-20;
                }
            }
            &-main {
                color: $primary;
                &::before {background-color: $green;}
            }
            &-white {
                color: $white;
                &::before {background-color: $white}
            }
            &-primary {
                color: $secondary;
                &::before {background-color: $primary}
            }
            &-secondary {
                color: $label;
                &::before {background-color: $secondary;}
            }
        }
    }

    @media screen and (max-width: $tablet-landscape-min) {
        .list {
            .list-item {
                width: 100%;
            }
        }
    }
</style>