//Creqating App
import { createApp } from 'vue'
import App from './App.vue'
//Base Vue Plugins {Router,Store}
import router from './router'
import store from './store'
//SCSS {Options in vue.config}
import './styles/main.scss'
//Components import 
import ui from '@/components/ui/index.js'
import sections from '@/components/sections/index.js'
import icons from '@/assets/icons/system/index.js'

//Registration components throu project
const app = createApp(App);
ui.forEach(component => {
    app.component(component.name, component)
});
sections.forEach(component => {
    app.component(component.name, component)
});
icons.forEach(component => {
    app.component(component.name, component)
});

app
.use(store)
.use(router)
.mount('#app')