<template>
  <section id="vacancies">
      <p class="t-3 t-w-sb t-center ff-alter mr-b-40">Вакансии</p>
      <Tabs @currentTab="activeTab" Center class="mr-b-40" Size="s" :Items="jobs" :active="tabActive"/>
      <div class="wrapper mr-b-20" v-for="tabs in jobs" :key="tabs.value" :class="{'wrapper-active' : tabActive === tabs.value}" :id="tabs.value">
            <div class="g-6 bg-white br-r-20 pd-fr-20 flex flex-row flex-just-center hidden" v-if="tabActive === 'archive'">
              <p class="p-2 t-center t-c-l t-lh-15">В архиве опубликованы <span class="t-w-sb">примеры</span> вакансий</p>
            </div>
            <div class="g-6 bg-white br-r-20 pd-fr-20 flex flex-just-center mr-t-20 hidden" v-if="tabActive === 'interships' && tabs.items.length === 0">
                <p class="p-1 t-center t-c-l mr-b-20">Сейчас у нас нет активных стажировок.</p>
                <p class="p-1 t-center t-c-l mr-b-20">Вы можете посмотреть <span class="t-w-sb t-c-sc">Архив</span></p>
                <p class="p-1 t-center t-c-l">или подписаться на нас на <Link  href="https://hh.ru/employer/3401349">hh.ru</Link></p>
            </div>
            <div class="g-6 bg-white br-r-20 pd-fr-20 flex flex-just-center mr-t-20 hidden" v-if="tabActive === 'vacancy' && tabs.items.length === 0">
                <p class="p-1 t-center t-c-l mr-b-20">Сейчас у нас нет активных вакансий.</p>
                <p class="p-1 t-center t-c-l mr-b-20">Вы можете посмотреть <span class="t-w-sb t-c-sc">Архив</span></p>
                <p class="p-1 t-center t-c-l">или подписаться на нас на <Link  href="https://hh.ru/employer/3401349">hh.ru</Link></p>
            </div>
          <JobCard v-for="card in tabs.items" :key="card.id" :cardInfo="card" :categoryValue="tabActive"/>
      </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name: "sVacancy",
    computed: {
        ...mapState({
            jobs: state => state.vacancies.jobs,
            tabActive: state => state.vacancies.tabActive
        }),
    },
    methods: {
        activeTab(data) {
            this.$store.state.vacancies.tabActive = data;
        }
    },
}
</script>

<style lang="scss" scoped>
    #vacancies {
        padding-top: $s-40;
        padding-bottom: $s-40;
        .wrapper {
            display: none;
            &-active {display: grid;}
            .hidden {
                grid-column-start: 4;
                height: fit-content;
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $tablet-landscape) {
        #vacancies {
            .wrapper {
                .g-10 {grid-column-start: 1; grid-column-end: span 10;}
                .hidden {
                    grid-column-start: 1;
                    grid-column-end: span 10;
                    margin-top: 0;
                }
            }
        }
    }
</style>