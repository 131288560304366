import { createStore } from 'vuex'
import { Header } from '@/store/Header.js'
import { Popups } from '@/store/Popups.js'
import { Footer } from '@/store/Footer.js'
import { Vacansies } from '@/store/vacancies.js'
import { Products } from '@/store/products.js'
import { History } from '@/store/history.js'
import { Team } from '@/store/team.js'
import { Partners } from '@/store/partners.js'
import { Clients } from '@/store/clients.js'

export default createStore({
  state: {
    popupOpen: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    header: Header,
    popup: Popups,
    footer: Footer,
    vacancies: Vacansies,
    products: Products,
    history: History,
    team: Team,
    partners: Partners,
    clients: Clients
  },
  getters: {
    
  }
})
