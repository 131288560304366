<template>
    <path
      d="M18.22,16.81l-4.28-4.5,0,0A6.25,6.25,0,1,0,8.75,15a6.16,6.16,0,0,0,3.78-1.28l4.24,4.47a1,1,0,0,0,1.45-1.38ZM8.75,13A4.25,4.25,0,1,1,13,8.75,4.25,4.25,0,0,1,8.75,13Z"
    />
</template>

<script>
export default {
  name: "iSearchM",
};
</script>
