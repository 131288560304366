<template>
    <sHero>
        <template #Title>Мы производим Российское ПО</template>
        <template #Description>ТП Лаб - одна из компаний, которая разрабатывает Российское программное обеспечение, сочетая его с хардверной частью</template>
        <template #Button><Btn Center Size="m" path="/about">Больше о компании</Btn></template>
    </sHero>
    <section id="patents flex flex-col">
        <!-- <p class="t-3 t-w-sb ff-alter t-center mr-b-20">Наши патенты</p> -->
        <!-- <p class="p-2 t-c-sc t-center t-w-m">В компании мы развиваем несколько направлений и патентуем нашу интеллектуальную собственность.</p> -->
        <ImageGallery :Images="patents"/>
    </section>
</template>

<script>
export default {
    name: 'IntellectualProperty',
    data() {
        return {
          patents: [
              {img: 'img/1.jpg', href: 'https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/019/615/869/2019615869-00001/DOCUMENT.PDF', alt: 'Свидетельство о Гос регистрации ЭВМ ТП Лаб'},
              {img: 'img/2.jpg', href: 'https://new.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2022660559&TypeFile=html', alt: 'Свидетельство о Гос регистрации ЭВМ ТП Лаб'},
            //   {img: 'img/aproove.png', alt: 'Свидетельство о Гос регистawdawdб'},
            //   {img: 'img/aproove.png', alt: 'Свидетельство о Госaawdawd регистрации ЭВМ ТП Лаб'},
            //   {img: 'img/aproove.png', alt: 'Свидетельство о Госawdawdрации ЭВМ ТП Лаб'},
          ]  
        }
    }
}
</script>

<style lang="scss" scoped>
    #patents {
        padding-top: $s-40;
        padding-bottom: $s-40;
        .img-wrapper {
            position: relative;
            max-height: 10rem;
            .img {height: 100%; width: auto;}
        }
    }
</style>