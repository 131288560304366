<template>
  <a
  class="btn"
  :class="[Color, Size, {'disabled' : Disabled},{'center' : Center}]"
  :href="path + href"
  @click="$router.push(path)"
  :target="isBlank"
  >
    <span v-if="Text === true" class="mr-r-15"><slot/></span>
    <div 
    class="icon"
    :class="`icon-${Size} icon-${Color}`"
    >
      <svg
      viewBox="0 0 20 20"
      :width="iconSize"
      :height="iconSize"
      >
        <g :class="Color">
          <slot name="Icon"><iArrowRightM/></slot>
        </g>
      </svg>
    </div>
  </a>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    Text: {
      type: Boolean,
      default: true
    },
    href: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    Color: {
      type: String,
      default: 'green'
    },
    Size: {
      type: String,
      default: 'l'
    },
    Center: {
      type: Boolean,
      default: false
    },
    Disabled: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: '20px'
    },
    Blank: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isBlank() {
      let blank = '';
      if (this.Blank === true) {
        blank = '_blank'
      } else {
        blank = '_self'
      }
      return blank
    }
  }
}
</script>

<style lang="scss" scoped>
 .btn {
   display: flex;
   flex-wrap: nowrap;
   cursor: pointer;
   position: relative;
   width: fit-content;
   height: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-weight: 800;
 }
 
 .blank {
   
 }

 .green {
   color: $white;
   background: $a-green;
   transition: $main-transition;
   fill: $green;
   &:hover {
     box-shadow: $shadow-small;
   }
 }

 .white {
   color: $a-green;
   background: $white;
   transition: $main-transition;
   fill: $white;
   &:hover {
     box-shadow: $shadow-small;
   }
 }

 .light {
   color: $secondary;
   background: $bg-light;
   box-shadow: $shadow-light;
   transition: $main-transition;
   fill: $bg-light;
   &:hover {
      box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(0, 0, 0, 0.1);;
   }
 }

 .dark {
    color: $a-green;
    background: $bg-light;
    box-shadow: $shadow-light;
    transition: $main-transition;
    fill: $bg-light;
    &:hover {
        box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 20px rgba(0, 0, 0, 0.1);;
    }
 }

 .l {
   font-size: $s-20;
   font-weight: 700;
   border-radius: $s-12;
 }

 .m {
   font-size: $s-15;
   font-weight: 700;
   border-radius: $s-10;
 }

 .icon {
   display: flex;
   @include flex-center;
   padding: $s-10;
   min-height: $s-20;
   min-width: $s-20;
   &-l {
      height: $s-30;
      width: $s-30;
      border-radius: $s-6;
      padding: $s-4;
   }
   &-m {
      height: $s-20;
      width: $s-20;
      border-radius: $s-4;
      padding: 0.2rem;
   }
   &-green {
     background: $white;
   }
   &-white {
     background: $a-green;
   }
   &-light {
     background: $a-green;
   }
   &-dark {
     background: $label;
   }
   img {
     width: 100%;
     height: 100%;
   }
 }

    .disabled {
     opacity: 0.5;
     cursor: not-allowed;
    }

    .center {
      @include margin-auto
    }
</style>