import iArrowLeft from '@/assets/icons/system/iArrowLeft.vue'
import iLink from '@/assets/icons/system/iLink.vue'
import iMail from '@/assets/icons/system/iMail.vue'
import iSearch from '@/assets/icons/system/iSearch.vue'
import iconBase from '@/assets/icons/system/iconBase.vue'
import iArrowRightM from '@/assets/icons/system/iArrowRightM.vue'
import iArrowLeftM from '@/assets/icons/system/iArrowLeftM.vue'
import iLinkM from '@/assets/icons/system/iLinkM.vue'
import iMailM from '@/assets/icons/system/iMailM.vue'
import iSearchM from '@/assets/icons/system/iSearchM.vue'
import iconFrame from '@/assets/icons/system/iconFrame.vue'

export default [
    iArrowLeft,
    iLink,
    iMail,
    iSearch,
    iconBase,
    iArrowRightM,
    iArrowLeftM,
    iLinkM,
    iMailM,
    iSearchM,
    iconFrame
]