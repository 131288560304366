<template>
<section id="cooperation">
  <sHero>
   <template #Title>
     Мы открыты <br class="mobile-hidden"> к сотрудничеству
   </template>
   <template #Description>
     Продуктовая компания. Распределённая команда.<br class="mobile-hidden"> Лучшие в том, что мы делаем. Растём вместе с клиентами и рынками.
   </template>
   <p class="p-2 t-c-pr t-w-sb t-center mr-b-20">Хотите стать нашим партнёром? Пишите нашему основателю:</p>
   <div class="flex flex-row">
     <Btn Blank class="mr-r-20" :href="'https://t.me/mit_idv'">Telegram</Btn>
     <Btn Blank :href="'https://wa.me/79219484926'">WhatsApp</Btn>
   </div>
    <div class="link link-1">
      <img src="~@/assets/illustrations/Cooperation/1.svg">
    </div>
    <div class="link link-2">
      <img src="~@/assets/illustrations/Cooperation/2.svg">
    </div>
    <div class="link link-3 pd-fr-20 br-r-20 bg-light">
      <img src="@/assets/logos/rerotor.svg">
    </div>
 </sHero>
 <div class="wrapper mr-b-40 mr-t-40">
   <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Партнёр</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">
     Вы придумали новый продукт на нашей платформе? Готовы применить наши продукты в новой нише? Есть идея крутой коллаборации? Пишите!</p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
   <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Торговый партнер</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">Вы можете продавать наши решения, помогая своим клиентам и расширяя свой бизнес!</p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
    <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Интегратор</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">Вам нравятся наши решения и вы хотите внести свой вклад? Вы можете работать над продуктами с нами и менять их под своего клиента, а мы будем прикрывать тылы и консультировать.</p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
    <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Инвестор</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">Мы готовы масштабироваться и расширяться, но нас не интересуют просто деньги. Если вы готовы делать свой вклад не только финансово, но знаете как лучше с нами работать - мы будем вам рады. </p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
    <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Поставщик</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">Мы используем хардверные решения и для этого нам нужно оборудование! Мы будем рады предложениям по сотрудничеству. </p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
    <div class="g-5 bg-white br-r-20 pd-fr-20">
     <p class="t-5 t-c-pr t-w-sb mr-b-10">Подрядчик</p>
     <p class="p-2 t-c-sc t-lh-15 mr-b-10">Наши продукты требуют внедрения, а мы не можем оказаться везде. Поэтому, если вы смелый и умелый, монтировали слаботочку в гипермаркетах, прокладывали сети на заводах, раскручивали сайты для сложной b2b аудитории или умеете ещё что-то очень нужное – смело пишите нам!</p>
     <!-- <Btn class="mr-t-auto" Size="m">Стать партнером</Btn> -->
   </div>
 </div>
 <sPartners :onPage="false" :withLink="true" :Items="$store.getters.getPartners"/>
</section>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
  #cooperation {
    .flex-row {
      @include flex-center;
    }
    .link {
      display: flex;
      @include flex-center;
      position: absolute;
      z-index: -1;
      &-1 {bottom: 20%; left: 0%;}
      &-2 {bottom: 20%; right: 0%;}
      &-3 {top: 15%; right: 20%;}
    }
    .wrapper {
      .g-5 {
        min-height: 20rem;
        &:nth-child(2n+1) {
        grid-column-start: 2;
        }
      }
    }
  }

  @media screen and (max-width: $tablet-landscape) {
    #cooperation {
      .wrapper {
        .g-5 {
          grid-column-end: span 5;
          &:nth-child(2n+1) {
            grid-column-start: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-landscape-min) {
    #cooperation {
      .link {
        &-1 {bottom: 20%; left: -5%;}
        &-2 {bottom: 20%; right: -5%;}
        &-3 {top: 5%; right: 10%;}
      }
      .wrapper {
        .g-5 {
          grid-column-end: span 4;
        }
      }
    }
  }

  @media screen and (max-width: $tablet-portrait) {
    #cooperation {
      .link {
        &-1 {bottom: 20%; left: -5%;}
        &-2 {bottom: 15%; right: -5%;}
        &-3 {top: 5%; right: 10%;}
      }
      .wrapper {
        .g-5 {
          grid-column-end: span 6;
          min-height: fit-content;
        }
      }
    }
  }


  @media screen and (max-width: $phone-landscape) {
    #cooperation {
      .flex-row {
        flex-direction: column;
        .btn {
          margin-right: 0;
          margin-bottom: $s-20;
        }
      }
      .link {
        &-1 {bottom: 20%; left: -5%;}
        &-2 {bottom: 5%; right: -15%;}
        &-3 {top: 0%; right: 20%;}
      } 
    }
  }

  @media screen and (max-width: $phone-portrait) {
    #cooperation {
      .link {
        opacity: 0.7;
        &-1 {bottom: 35%; left: -10%;}
        &-2 {bottom: -2%; right: 15%;}
        &-3 {top: 0%; right: 10%;}
      } 
    }
  }


$tiny-flip-phone: 240px;
$flip-phone: 320px;
$phone-portrait: 480px;
$phone-landscape: 640px;
$tablet-portrait: 768px;
$tablet-landscape-min: 940px;
$tablet-landscape: 1200px;
$desktop: 1440px;
$xl-desktop: 1920px;

</style>