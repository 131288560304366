<template>
  <section id="team" class="wrapper pd-t-80">
    <div class="g-3 br-r-20 pd-fr-20 bg-white">
      <h3 class="t-3 t-w-sb t-c-pr mr-b-20 t-lh-13">Наша команда</h3>
      <p class="p-2 t-c-sc t-w-m mr-b-20" v-if="buttonOn === false">
        У нас есть место для молодых и опытных специалистов, тех, кто решил поменять карьеру, кому душно в больших
        компаниях, кто делает первые шаги в карьере, кто хочет быть ближе к продукту, к внедрениям, кто стремится к
        развитию, кто умеет работать в команде и самостоятелен.
      </p>
      <p class="p-2 t-c-sc t-w-m mr-b-20" v-if="buttonOn === true">
        Мы объединяем молодых и опытных специалистов, заинтересованных в развитии себя, с общими ценностями, через
        интерес к действующим и будущим продуктам компании
      </p>
      <Btn v-if="buttonOn === true" Size="m" :path="'/career/'" :href="'#team'">Вся команда</Btn>
    </div>
    <div class="g-3 br-r-20 pd-fr-20 t-center bg-light"
         v-for="item in teamItems"
         :key="item"
    >
      <div :class="item.mask" class="mask mr-b-20">
        <img :src="require(`@/assets/img/team/${item.img}`)" :alt="item.name">
      </div>
      <p class="p-1 t-c-pr t-w-sb ff-alter mr-b-10">{{ item.name }}</p>
      <p class="p-2 t-c-sc t-w-r" v-html="item.position"></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sTeam',
  props: {
    teamItems: {
      type: Array
    },
    buttonOn: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
	.g-3 {
		min-height: 32rem;

		.btn {
			margin-top: auto;
		}

		.mask {
			position: relative;
			width: 100%;

			img {
				width: 100%;
			}

			&-1 {
				mask: url('~@/assets/masks/team/Mask_1.svg') 0 0 /100% 100% no-repeat;
			}

			&-2 {
				mask: url('~@/assets/masks/team/Mask_2.svg') 0 0 /100% 100% no-repeat;
			}

			&-3 {
				mask: url('~@/assets/masks/team/Mask_3.svg') 0 0 /100% 100% no-repeat;
			}

			&-4 {
				mask: url('~@/assets/masks/team/Mask_4.svg') 0 0 /100% 100% no-repeat;
			}

			&-5 {
				mask: url('~@/assets/masks/team/Mask_5.svg') 0 0 /100% 100% no-repeat;
			}

			&-6 {
				mask: url('~@/assets/masks/team/Mask_6.svg') 0 0 /100% 100% no-repeat;
			}

			&-7 {
				mask: url('~@/assets/masks/team/Mask_7.svg') 0 0 /100% 100% no-repeat;
			}
		}
	}
}

@media screen and (max-width: $tablet-landscape) {
	.wrapper {
		grid-template-columns: repeat(12, 1fr);

		.g-3 {
			grid-column-end: span 3;
			min-height: fit-content;
		}
	}
}

@media screen and (max-width: $tablet-landscape-min) {
	.wrapper {
		grid-template-columns: repeat(9, 1fr);

		.g-3 {
			grid-column-end: span 3;
		}
	}
}

@media screen and (max-width: $tablet-portrait) {
	.wrapper {
		grid-template-columns: repeat(8, 1fr);

		.g-3 {
			grid-column-end: span 4;
		}
	}
}

@media screen and (max-width: $phone-portrait) {
	.wrapper {
		grid-template-columns: repeat(4, 1fr);

		.g-3 {
			grid-column-end: span 4;

			&:first-child {
				min-height: 32rem;
			}
		}
	}
}

</style>
