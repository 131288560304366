<template>
  <svg xmlns="http://www.w3.org/2000/svg"
  :height="height"
  :width="width"
  viewBox="0 0 20 20"
  :class="Color"
  >
     <slot/>
  </svg>
</template>

<script>
export default {
    name: 'iconFrame',
    props: {
        height: {
            type: [Number, String],
            default: 20
        },
        width: {
            type: [Number, String],
            default: 20
        },
        Color: {
            type: String,
            default: 'green'
        }
    }
}
</script>

<style lang="scss" scoped>
    .white {fill: $green;}
    .green {fill: $white;}
    .light {fill: $white;}
</style>