<template>
  <label :for="Name" class="label">
      <input
      @input:Value="Value = $event.target.value"
      v-model="Value"
      class="input"
      :class="[Size ? 'input-m' : 'input-l', `input-${Color}`]"
      :type="Type"
      :name="Name"

      :placeholder="Placeholder"
      >
      <div
      v-if="Button === true"
      id="icon-button"
      class="icon-button"
      :class="[Size ? 'icon-button-m' : 'icon-button-l']"
      >
      <svg
        viewBox="0 0 20 20"
        :width="iconWidth"
        :height="iconHeight"
      >
        <g :class="`input-${Color}`">
            <slot name="icons"><iArrowRightM/></slot>
        </g>
      </svg>
      </div>
  </label>
</template>

<script>
export default {
    name: 'Input',
    watch: {
        Value(newValue) {
            if(newValue !== '') {
                document.getElementById('icon-button').classList.add('icon-button-active')
            } else {
                document.getElementById('icon-button').classList.remove('icon-button-active')
            }
        }
    },
    data() {
        return {
            Value: '',
            l: {inpusSize: 'input-l', buttonSize: 'icon-button-l'},
            m: {inpusSize: 'input-m', buttonSize: 'icon-button-m'},
            white: 'input-white',
            light: 'input-light',
        }
    },
    props: {
        Class: {
            type: Object
        },
        Type: {
            type: String,
            default: 'text'
        },
        Name: {
            type: String
        },
        Placeholder: {
            type: String
        },
        Size : {
            type: Boolean,
            default: false
        },
        Color : {
            type: String,
            default: 'white'
        },
        Button : {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss" scoped>
    .label {
        position: relative;
        display: flex;
        width: fit-content;
        .input {
            padding: $s-10;
            font-weight: 400;
            color: $primary;
            transition: $main-transition;
            &-l {
                border-radius: $s-12;
                font-size: $s-20;
                line-height: 1.5;
                padding-right: $s-60;
            }
            &-m {
                border-radius: $s-10;
                font-size: $s-15;
                line-height: 1.3;
                padding-right: $s-40;
            }
            &-white {
                background: $bg-white;
                fill: $white;
                &:focus {
                    color: $secondary;
                    box-shadow: $shadow-small;
                }
                &:hover {
                    box-shadow: $shadow-small;
                }
            }
            &-light {
                background: $bg-light;
                box-shadow: $shadow-light;
                fill: $white;
                &:focus {
                    color: $secondary;
                    box-shadow: $shadow-light-hover;
                }
                &:hover {
                    box-shadow: $shadow-light-hover;
                }
            }
            &::placeholder {
                color: $label;
            }
        }
        
        .icon-button {
            display: flex;
            position: absolute;
            background: $label;
            transition: $main-transition;
            &-l {
                top: $s-10;
                right: $s-10;
                width: $s-30;
                height: $s-30;
                border-radius: $s-6;
                padding: $s-6;
            }
            &-m {
                top: $s-10;
                right: $s-10;
                width: $s-20;
                height: $s-20;
                border-radius: $s-4;
                padding: $s-4;
            }
            &-active {
                background: $a-green;
                &:hover {
                    box-shadow: $shadow-small;
                }
            }
        }
    }
</style>