export const History = {
    state: () => ({
        historyItems: [
            {
              year: "1999",
              events: [
                {
                  name: "Дмитрий Ивченко и Сергей Раков",
                  class: "g-6 bg-white",
                  description: 'встречаются в ИТМО'
                },
                {
                  name: "Приложение для разбревновки",
                  class: "g-6 bg-light",
                  description: 'LogHouse запускается на ДСК Рощинский Дом'
                },
              ],
            },
            {
              year: "2000",
              events: [
                {
                  name: "Приложение Мой Салон",
                  class: "g-12 bg-white",
                  description: "запускается в салоне красоты Май"
                },
              ],
            },
            {
              year: '2005',
              events: [
                {
                  name: 'Мой Салон',
                  class: 'g-6 bg-white',
                  description: 'используется в салонах красоты по всей России'
                },
                {
                  name: 'LogHouse',
                  class: 'g-6 bg-light',
                  description: 'становится популярным приложением для разбревновки'
                }
              ]
            },
            {
              year: "2009",
              events: [
                {
                  name: "Система ПоЗаписи",
                  class: "g-12 bg-white",
                  description: "начинает работать в репетиционных базах Октава и ещё десятках заведений в разных регионах России"
                },
              ],
            },
            {
              year: "2013", 
              events: [
                {
                  name: "Видеоценники RetailRotor",
                  class: "g-4 bg-white",
                  description:
                    "впервые устанавливаются в магазинах М.видео",
                },
                {
                  name: "Комплекс LogHouse",
                  class: "g-4 bg-light",
                  description: 'включает приложение для упаковки LogPack'
                },
                {
                  name: "Eco House Group",
                  class: "g-4 bg-light",
                  description: "запускает производственную линию Logmatic от Makron Oy и LogHouse"
                },
              ],
            },
            {
              year: "2014",
              events: [
                {
                  name: "Начинаются исследования",
                  class: "g-12 bg-white",
                  description: 'по проекту KnotInspector'
                },
              ],
            },
            { 
                year: "2015",
                events: [
                    { 
                    name: "KnotInspector участвует", 
                    class: "g-5 bg-white",
                    description: 'в выставке WebReady 2015 в Сколково и в европейской выставке LIGNA 2015'
                    },
                    { 
                    name: 'Появляется название TruePositive Lab', 
                    class: "g-4 bg-light",
                    description: 'операции переходят на ООО "ТП Лаб"'
                    },
                    { 
                    name: 'Европейская Медиагруппа', 
                    class: "g-3 bg-light",
                    description: 'устанавливает точки удалённого контроля радиоэфира FM Monitor'
                    },
                ] 
            },
            {
              year: "2016",
              events: [
                {
                  name: "Софья Тимофеева",
                  class: "g-4 bg-white",
                  description: 'становится партнёром в проекте RetailRotor'
                },
                {
                  name: "В магазинах DNS",
                  class: "g-4 bg-light",
                  description: 'появляются видеоценники RetailRotor'
                },
                {
                  name: 'Русская Медиагруппа',
                  class: 'g-4 bg-light',
                  description: 'запускает проект мониторинга радиоэфира в 5-ти городах вещания Русского Радио на платформе FM Monitor'
                }
              ],
            },
            { 
                year: "2018",
                events: [
                    {
                        name: "Стартует проект DS+DL",
                        class: "g-3 bg-white",
                        description: 'для магазинов М.Видео'
                    },
                    {
                        name: "Собственная команда монтирует",
                        class: "g-3 bg-light",
                        description: 'видеоценники на ТВ в 30 магазинах'
                    },
                    {
                        name: "Рощинский Дом",
                        class: "g-3 bg-light",
                        description: 'запускает новую производственную линию Logmatic от Makron Oy и LogHouse'
                    },
                ]
            },
            { 
                year: "2019", 
                events: [
                    {
                        name: "МегаФон",
                        class: "g-3 bg-white",
                        description: 'открывает магазины нового формата Experience Store с видеоценниками RetailRotor'
                    },
                    {
                        name: "Придумали название TruePositive Kit",
                        class: "g-3 bg-light",
                        description: 'для компании-интегратора, появилось ООО "ТП Кит"'
                    },
                    {
                        name: "Построили офис",
                        class: "g-3 bg-light",
                        description: 'на Приморском 62-1-1-62-Н и переехали туда'
                    }
                ] 
            },
            { 
                year: "2020",
                events: [
                    {
                        name: "528 магазинов Эльдорадо ",
                        class: "g-3 bg-white",
                        description: 'получают медиаплееры RetailRotor за 2 недели'
                    },
                    {
                        name: "МТС открывает",
                        class: "g-3 bg-light",
                        description: 'магазины нового формата с видеоценниками RetailRotor'
                    },
                    {
                        name: 'RetailRotor обслуживает 100%',
                        class: "g-3 bg-light",
                        description: 'экранов во всех магазинах Эльдорадо '
                    },
                    {
                      name: 'Радиостанция Маяк',
                      class: 'g-3 bg-light',
                      description: 'запускает мониторинг эфира в регионах на платформе FM Monitor'
                    }
                ]
            },
            { 
                year: "2021",
                events: [
                    {
                        name: "KnotInspector устанавливается",
                        class: "g-3 bg-white",
                        description: 'на заводы Буллит и Тимпродукт'
                    },
                    {
                        name: "Магазины CreditAsia",
                        class: "g-3 bg-light",
                        description: 'в Ташкенте запускают видеоценники RetailRotor'
                    },
                    {
                        name: "Монтаж видеоценников",
                        class: "g-3 bg-light",
                        description: 'полностью передан подрядчикам'
                    },
                    {
                      name: 'Старт продаж',
                      class: 'g-3 bg-light',
                      description: 'серийной версии RetailRotor'
                    }
                ]
            },
            {
              year: '2022',
              events: [
                {
                  name: 'Созданы версии RetailRotor',
                  class: 'g-6 bg-white',
                  description: 'для автосалонов, магазинов одежды и супермаркетов'
                },
                {
                  name: 'Павел Озеров',
                  class: 'g-6 bg-light',
                  description: 'присоединяется к проекту KnotInspector'
                }
              ]
            }
          ],
          selected: "1999",
    }),
    actions: {

    },
    mutations: {

    },
    getters: {
        getHistory(state) {
            return state.historyItems
        }
    }
}