<template>
  <section class="hero-tr" :id="Id">
      <div class="wrapper">
    <div class="g-6 flex flex-just-center">
      <h1 :class="Size" class="t-w-sb t-lh-13 mr-b-20 if-empty">
        <slot name="Title">Заголовок</slot>
      </h1>
      <p class="p-2 t-w-r t-c-sc t-lh-15 ff-alter if-empty">
        <slot name="Paragraph">Параграф</slot>
      </p>
    </div>
    <div class="g-6" v-if="Image === true">
      <div class="image-wrapper">
        <img :src="require(`@/assets/${heroImage}`)" class="hero-image" />
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "sHeroTransperent",
  props: {
    Id: {
      type: String,
      default: "HeroTransperent",
    },
    heroImage: {
      type: String,
      default: "img/ei-crop.png",
    },
    Image: {
      type: Boolean,
      default: true,
    },
    Size: {
      type: String,
      default: "t-2",
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-tr {
    padding: $s-40 0;
    .wrapper {
        .g-6 {
            .image-wrapper {
                width: 100%;
                position: relative;
                .hero-image {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet-landscape) {
    .hero-tr {
        .wrapper {
            grid-template-columns: repeat(10, 1fr);
            .g-6 {grid-column-end: span 5;}
        }
    }
}

@media screen and (max-width: $tablet-landscape-min) {
    .hero-tr {
        padding: $s-20 0;
        .wrapper {
            grid-template-columns: repeat(9, 1fr);
            .g-6 {
                grid-column-end: span 9;
                &:nth-child(2) {
                    grid-row-start: 1;
                }
            }
        }
    }
}
</style>
